<template>
  <g id="palette" clip-path="url(#clip0_502_1863)">
    <g id="Icon">
      <path
        d="M1.66797 10.0003C1.66797 14.6027 5.39893 18.3337 10.0013 18.3337C11.382 18.3337 12.5013 17.2144 12.5013 15.8337V15.417C12.5013 15.03 12.5013 14.8365 12.5227 14.674C12.6704 13.5522 13.5532 12.6694 14.675 12.5217C14.8374 12.5003 15.031 12.5003 15.418 12.5003H15.8346C17.2153 12.5003 18.3346 11.381 18.3346 10.0003C18.3346 5.39795 14.6037 1.66699 10.0013 1.66699C5.39893 1.66699 1.66797 5.39795 1.66797 10.0003Z"
        stroke="white"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M5.83464 10.8337C6.29487 10.8337 6.66797 10.4606 6.66797 10.0003C6.66797 9.54009 6.29487 9.16699 5.83464 9.16699C5.3744 9.16699 5.0013 9.54009 5.0013 10.0003C5.0013 10.4606 5.3744 10.8337 5.83464 10.8337Z"
        stroke="white"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M13.3346 7.50033C13.7949 7.50033 14.168 7.12723 14.168 6.66699C14.168 6.20676 13.7949 5.83366 13.3346 5.83366C12.8744 5.83366 12.5013 6.20676 12.5013 6.66699C12.5013 7.12723 12.8744 7.50033 13.3346 7.50033Z"
        stroke="white"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M8.33464 6.66699C8.79487 6.66699 9.16797 6.2939 9.16797 5.83366C9.16797 5.37342 8.79487 5.00033 8.33464 5.00033C7.8744 5.00033 7.5013 5.37342 7.5013 5.83366C7.5013 6.2939 7.8744 6.66699 8.33464 6.66699Z"
        stroke="white"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_502_1863">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
