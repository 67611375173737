<template>
  <div
    :class="[
      `footer-container-mobile --${props.location}`,
      { '--border': isOpen }
    ]"
  >
    <div class="copy-right-mobile-container">
      <button class="footer-button" @click="openFooter">
        <IconComponent>
          <IconChevronDown v-if="!isOpen"> </IconChevronDown>
          <IconChevronUp v-else> </IconChevronUp
        ></IconComponent>
      </button>
      <div :class="`game__copy-right--mobile --${props.location}`">
        <span
          >Made with ♥ by
          <a href="https://www.inovex.de/de/kontakt/" target="_blank">
            inovex</a
          ></span
        >
      </div>
      <div :class="[`tooltip-mobile --${props.location}`]">
        <button
          :disabled="currentRequests < MAX_CONCURRENT_REQUESTS"
          class="footer-button --traffic"
          @click="toogleTooltip"
        >
          <IconComponent
            :show-title="false"
            :class="{
              'icon-traffic--mobile': true,
              '--too-high': currentRequests >= MAX_CONCURRENT_REQUESTS
            }"
          >
            <IconSpeedometer></IconSpeedometer>
          </IconComponent>
          <span
            :class="{
              tooltiptext_mobile: true,
              '--too-high': currentRequests >= MAX_CONCURRENT_REQUESTS,
              '--visible': isTooltipVisible
            }"
            >increased data traffic</span
          >
        </button>
      </div>
    </div>
    <div
      v-show="isOpen"
      class="p-4 transition-max-height duration-500 ease-in-out"
      :class="{
        'max-h-0 overflow-hidden': !isOpen,
        'max-h-96 copy-right-mobile__legal': isOpen
      }"
    >
      <div><a @click="$router.push(ROUTES.TEAM)">Team</a></div>
      <div>
        <a href="https://www.inovex.de/en/datenschutz/" target="_blank"
          >Data protection</a
        >
      </div>
      <div>
        <a href="https://www.inovex.de/en/impressum/" target="_blank"
          >Legal Notice</a
        >
      </div>
      <div><a @click="$router.push(ROUTES.DISCLAIMER)">Disclaimer</a></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ROUTES } from '@/router/routes';
import IconComponent from '@/components/IconComponent.vue';
import IconSpeedometer from '@/assets/VueIcons/IconSpeedometer.vue';
import { currentRequests, MAX_CONCURRENT_REQUESTS } from '@/stores/gameStore';
import { ref } from 'vue';
import IconChevronDown from '@/assets/VueIcons/IconChevronDown.vue';
import IconChevronUp from '@/assets/VueIcons/IconChevronUp.vue';
import { updateCurrentRequests } from '@/utils/utilizatioHelper';
const props = defineProps({
  location: {
    type: String
  }
});
const isOpen = ref(false);
const isTooltipVisible = ref(false);
const openFooter = () => {
  isOpen.value = !isOpen.value;
};
const toogleTooltip = () => {
  isTooltipVisible.value = !isTooltipVisible.value;
};
setInterval(updateCurrentRequests, 5000);
</script>

<style lang="scss">
.footer-container-mobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.footer-container-mobile.--start {
  padding: 0;
  width: 100%;
}

.footer-container-mobile.--play {
  padding: 0 32px;
}

.footer-container-mobile.--end {
  padding: 0 32px;
}

.copy-right-mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.game__copy-right--mobile {
  display: flex;
  margin: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 0.8em;
  justify-self: start;
  align-self: center;
  justify-content: space-between;
  color: $text-tertiary;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  font-weight: 400;

  place-self: end;

  span > a {
    color: $text-tertiary;
    font-weight: 700;
    text-decoration: none;
  }
}

.game__copy-right--mobile.--play {
  top: unset;
  bottom: 24px;
}

.copy-right-mobile__legal {
  width: 100%;
  display: grid;
  padding: 16px 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  place-content: space-between;
  text-align: start;
  gap: 16px 32px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: transparent;
  border-top: solid $secondary-border 1px;

  a {
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: 0.8em;
    line-height: 20px;
    color: $text-tertiary;
    text-decoration: none;
  }
}

.icon-traffic--mobile {
  color: #94969c;
  &.--too-high {
    color: #f04438;
  }
}

.tooltip-mobile {
  position: relative;
  display: inline-block;
  margin-left: 32px;
}

.tooltip-mobile .tooltiptext_mobile {
  visibility: hidden;
  background-color: #161b26;
  color: #f5f5f6;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  width: 120px;
  bottom: 5%;
  right: 100%;
  margin-left: -60px;
  position: absolute;
  z-index: 1;
}

.tooltiptext_mobile.--too-high.--visible {
  visibility: visible;
}

.footer-button {
  background-color: transparent;
  color: $text-tertiary;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin-top: 16px;
  &.--traffic {
    margin-top: 8px;
  }
}
</style>
