<template>
  <path
    id="Icon"
    d="M12.5 3.33366V1.66699M12.5 13.3337V11.667M6.66667 7.50033H8.33333M16.6667 7.50033H18.3333M14.8333 9.83366L15.8333 10.8337M14.8333 5.16699L15.8333 4.16699M2.5 17.5003L10 10.0003M10.1667 5.16699L9.16667 4.16699"
    stroke="white"
    stroke-width="1.66667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
