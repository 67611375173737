<template>
  <g id="line-chart-up-05">
    <path
      id="Icon"
      d="M15.5013 8.33366L12.6394 11.1956C12.4744 11.3606 12.3919 11.4431 12.2967 11.474C12.213 11.5012 12.1229 11.5012 12.0392 11.474C11.9441 11.4431 11.8616 11.3606 11.6966 11.1956L9.30604 8.80506C9.14103 8.64006 9.05853 8.55755 8.96339 8.52664C8.87971 8.49945 8.78956 8.49945 8.70588 8.52664C8.61074 8.55755 8.52824 8.64006 8.36323 8.80506L5.5013 11.667M18.8346 10.0003C18.8346 14.6027 15.1037 18.3337 10.5013 18.3337C5.89893 18.3337 2.16797 14.6027 2.16797 10.0003C2.16797 5.39795 5.89893 1.66699 10.5013 1.66699C15.1037 1.66699 18.8346 5.39795 18.8346 10.0003Z"
      stroke="#CECFD2"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>
