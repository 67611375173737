<template>
  <g id="speedometer" clip-path="url(#clip0_186_4042)">
    <path
      d="M4.16602 10.0003C4.16602 6.77866 6.77769 4.16699 9.99935
    4.16699M13.7493 6.25033L9.99927 10.0003M18.3327 10.0003C18.3327 14.6027
    14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602
    10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699
    18.3327 5.39795 18.3327 10.0003ZM10.8327 10.0003C10.8327 10.4606 10.4596
    10.8337 9.99935 10.8337C9.53911 10.8337 9.16602 10.4606 9.16602
    10.0003C9.16602 9.54009 9.53911 9.16699 9.99935 9.16699C10.4596 9.16699
    10.8327 9.54009 10.8327 10.0003Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_186_4042">
      <rect
        width="20"
        height="20"
        transform="translate(0.5 0.987305)"
        fill="none"
      />
    </clipPath>
  </defs>
</template>
