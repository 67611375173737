<template>
  <!--  This component is used to display a countdown from 4 to 1. The 'finished' event is emitted when the countdown ends, triggering the 'startRound' method. The number '4' never gets shown to the player, because the animation takes 1 second. Thus the user gets displayed a countdown starting at '3'-->
  <Vue3autocounter
    ref="game-countdown"
    class="number"
    :startAmount="4"
    :endAmount="1"
    :duration="4"
    separator=""
    :autoinit="true"
    @finished="startRound()"
  />
</template>

<script setup lang="ts">
import { ROUTES } from '@/router/routes';
import router from '@/router';
import Vue3autocounter from 'vue3-autocounter';

function startRound() {
  router.push(ROUTES.PLAY);
}
</script>

<style lang="scss">
.number {
  text-align: center;
  font-size: 278px;
  font-style: normal;
  font-weight: 700;
  line-height: 224px;
  width: 100%;
  margin: auto;
  z-index: 99999;
  max-height: 500px;
  @include fadeIn;
}
</style>
