<template>
  <!--
TextInput component. This component is used to create a text input field with a label.
-->
  <div class="text-input">
    <label for="input-field"> {{ props.labelName }}</label>
    <input
      type="input"
      id="input-field"
      :placeholder="props.placeHolder"
      maxlength="32"
      autofocus
      @blur="handleBlur"
      @click="scrollPrimaryButtonToBottom"
      v-model.trim="userStore.name"
      @keydown.enter.prevent="props.onEnter"
    />
  </div>
</template>

<script setup lang="ts">
import { useKeyboardStore } from '@/stores/keyboardStore';
import { useUserStore } from '@/stores/userStore';

const props = defineProps({
  labelName: String,
  placeHolder: String,
  onEnter: Function
});

const keyboardStore = useKeyboardStore();

function scrollPrimaryButtonToBottom() {
  keyboardStore.setExpanded(true);
}

function handleBlur() {
  keyboardStore.setExpanded(false);
}

const userStore = useUserStore();
</script>

<style lang="scss">
.text-input {
  display: flex;
  gap: $spacing-sm;
  flex-direction: column;
  label {
    color: $text-secondary;
    @include text-sm_medium;
    margin-bottom: $spacing-sm;
  }

  input {
    @include textInput;
  }
}
</style>
