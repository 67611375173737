<template>
  <!--
SVG Icon component. This component is a wrapper for SVG icons.
-->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :viewBox="`0 0 ${props.width} ${props.height}`"
    :aria-labelledby="props.iconName"
    role="presentation"
    :color="iconColor"
  >
    <title v-if="showTitle" :id="props.iconName" lang="en">
      {{ props.iconName }} icon
    </title>

    <slot />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  iconName: {
    type: String,
    default: 'box'
  },
  width: {
    type: [Number, String],
    default: 24
  },
  height: {
    type: [Number, String],
    default: 24
  },
  iconColor: {
    type: String,
    default: 'currentColor'
  },
  showTitle: {
    type: Boolean,
    default: true
  }
});
</script>

<style lang="scss">
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
