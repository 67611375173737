export enum DialogType {
  WON = 'won',
  LOST = 'lost',
  GAME = 'game'
}

export type Dialog = {
  id: string | number;
  characterImg: string;
  characterImg2?: string;
  forgerImg: string;
  name: string;
  name2?: string;
  dialog: DialogText;
};

export type DialogText = { [key: string]: string };
