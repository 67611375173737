<template>
  <!--
Vue component that serves as a divider with a text in the middle.
The divider consists of two lines and a text slot in between.
-->
  <div class="divider">
    <div class="divider__line" />
    <div class="divider__text"><slot /></div>
    <div class="divider__line" />
  </div>
</template>

<style lang="scss">
.divider {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 0px;
}

.divider__line {
  flex: 1;
  border-top: 1px solid $bg-disabled;
}

.divider__text {
  padding: 0 24px;
  color: $text-tertiary;
  @include text-sm_medium;
}
</style>
