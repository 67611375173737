<template>
  <!--
This component displays a series of words, one by one, with a delay between each word.
The words are loaded in the DOM, but hidden (refer to startWordByWord).
-->

  <template :key="index" v-for="(word, index) in words">
    <br v-if="word.endsWith('\n')" />
    <span :class="`game-dialog__dialog-text word-num__${index}`" v-else>
      {{ word }}&nbsp;
    </span>
  </template>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  text: {
    type: String,
    required: true
  }
});

watch(
  () => props.text,
  (newValue) => {
    if (newValue) {
      wordIndex.value = 0;
      words.value = newValue?.match(/(\S+|\n)/g);
      const visibleElements = document.querySelectorAll('.visible');

      visibleElements.forEach((element) => {
        element.classList.remove('visible');
      });
      startWordByWord();
    }
  }
);

const wordIndex = ref(0);
const words = ref([]);
const emit = defineEmits(['ShowWordByWord:finished']);

function finished() {
  emit('ShowWordByWord:finished');
}
function startWordByWord() {
  const interval = setInterval(() => {
    if (wordIndex.value !== undefined && wordIndex.value < words.value.length) {
      const wordElement = document.getElementsByClassName(
        `word-num__${wordIndex.value}`
      )[0];

      if (wordElement) {
        wordElement.classList.add('visible');
      }
      wordIndex.value++;
    } else {
      clearInterval(interval);
      finished();
    }
  }, 250);
}

onMounted(() => {
  words.value = props.text.match(/(\S+|\n)/g);
  startWordByWord();
});
</script>

<style lang="scss">
.game-dialog__dialog-text {
  color: $text-primary;
  text-align: center;
  display: inline-block;
  opacity: 0;
  white-space: pre-line;
  @include text-xl-semibold;

  &.visible {
    transition: opacity 1s;
    transition-delay: 0.1s;
    transition-timing-function: ease-in-out;
    opacity: 1;
  }

  @include phone {
    @include text-md_regular;
  }
}
</style>
