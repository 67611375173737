<template>
  <div class="textarea__container">
    <label class="textarea__label" for="prompt-input"> Image prompt</label>
    <div class="textarea__grow-wrap">
      <textarea
        ref="textareaRef"
        autofocus
        required
        :maxlength="maxLength"
        id="prompt-input"
        name="user-prompt"
        class="textarea-text"
        oninvalid="this.setCustomValidity('Input for Image Generation is required')"
        @input="adjustTextarea"
        @click="() => keyboardStore.setExpanded(true)"
        @blur="() => keyboardStore.setExpanded(false)"
        :placeholder="inputPlaceholder"
        v-model="gameStore.userInput"
        @keydown.enter.exact.prevent="triggerSubmit"
        :disabled="disabled"
      />
    </div>

    <div class="textarea__length-info">
      {{ maxLength - gameStore.userInput.length }} characters left
    </div>
  </div>
</template>

<script setup lang="ts">
import { GameStatus, RoundStatus, useGameStore } from '@/stores/gameStore';
import { useKeyboardStore } from '@/stores/keyboardStore';
import { useUserStore } from '@/stores/userStore';
import { isPhone } from '@/utils/responsiveHelper';
import { computed, onMounted, ref } from 'vue';

const gameStore = useGameStore();
const userStore = useUserStore();

const textareaRef = ref(null);

const keyboardStore = useKeyboardStore();

const maxLength = 200;
const emit = defineEmits(['TextArea:triggerSubmit']);

function triggerSubmit() {
  if (gameStore.userInput.length !== 0) {
    emit('TextArea:triggerSubmit');
  }
}

const adjustTextarea = () => {
  if (isPhone()) {
    const textarea = textareaRef.value;
    const previousScrollHeight = textarea.scrollHeight;
    textarea.style.height = 'auto';
    textarea.style.height = '1px'; // Set a small height to trigger the scrollHeight

    textarea.style.height = textarea.scrollHeight + 'px';

    // Check if the scrollHeight has increased
    if (textarea.scrollHeight > previousScrollHeight) {
      // Scroll the entire screen to show the last line of the textarea
      window.scrollBy(0, 20);
    }
  }
};

const disabled = computed(() => {
  return (
    gameStore.status === GameStatus.INITIAL ||
    gameStore.roundStatus === RoundStatus.EXPIRED ||
    gameStore.roundStatus === RoundStatus.SUBMITTED ||
    gameStore.roundStatus === RoundStatus.INITIAL ||
    gameStore.loadingImage ||
    gameStore.survived ||
    userStore.lives === 0
  );
});

const inputPlaceholder = computed(() => {
  if (gameStore.userInput !== '') {
    return gameStore.userInput;
  } else {
    return 'Your prompt...';
  }
});

onMounted(() => {
  adjustTextarea(); // Call adjustTextarea on mounted
});
</script>

<style scoped lang="scss">
.textarea__label {
  color: $text-secondary;
  @include text-sm_medium;

  @include phone {
    display: none;
  }
}

.textarea__container {
  position: relative;
  flex: 1;
  width: 100%;
  max-height: 15vh;

  border-radius: $radius-md;
  display: flex;
  gap: $spacing-md;
  flex-direction: column;

  @include phone {
    max-height: unset;
  }
}

.textarea-text {
  resize: none;
  font-family: 'Lato';

  @include text-md_regular;
  @include textInput;

  @include phone {
    min-height: 40px;
    /* height: fit-content; */
  }
}

.textarea__grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  width: 100%;
  height: 100%;
}
.textarea__grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.textarea__grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.textarea__grow-wrap > textarea,
.textarea__grow-wrap::after {
  /* Identical styling required!! */

  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.textarea__length-info {
  @include text-sm_regular;
  color: $text-tertiary;

  @include phone {
    display: none;
  }
}
</style>
