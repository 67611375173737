<template>
  <BadgeComponent :variant="getLifeStatus()">
    <div class="lives">
      <span v-for="x in userStore.lives" v-bind:key="x" :class="`life-${x}`">
        ♥︎
      </span>
    </div>
    <button
      class="life-badge__surrender"
      :disabled="gameStore.status !== GameStatus.InProgress"
      type="submit"
      @click="handleGameOver()"
    >
      Surrender
    </button>
  </BadgeComponent>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';
import { handleGameOver } from '@/utils/roundEndHelper';
import { useGameStore, GameStatus, RoundStatus } from '@/stores/gameStore';
import { useUserStore } from '@/stores/userStore';

import BadgeComponent from '@/components/BadgeComponent.vue';

const gameStore = useGameStore();
const userStore = useUserStore();

function getLifeStatus() {
  switch (userStore.lives) {
    case 3:
      return 'success';
    case 2:
      return 'warning';
    default:
      return 'error';
  }
}
const scores = reactive({
  current: 0,
  previous: 0
});

watch(
  () => userStore.score,
  (newScore, previousScore) => {
    scores.current = newScore;
    scores.previous = previousScore || 0;
  }
);

watch(
  () => userStore.lives,
  (livesAfter, livesBefore) => {
    if (livesAfter == userStore.initialUserLives) {
      document
        .querySelectorAll(`.lives > *`)
        .forEach((value) => value.classList.remove('lost'));
      return;
    }
    window.setTimeout(
      () =>
        document.querySelector(`.life-${livesBefore}`)?.classList.add('lost'),
      gameStore.roundStatus != RoundStatus.EXPIRED ? 1500 : 0
    );
  }
);
</script>

<style scoped lang="scss">
.lives {
  justify-content: flex-end;

  > span {
    display: inline-block;
    opacity: 1;
    z-index: 100;
    margin-left: 1px;
  }

  .lost {
    animation: lostLife 1.5s;
    opacity: 0;
    width: 0;
  }
}

.life-badge__surrender {
  padding: $spacing-xxs 10px;
  border-radius: $radius-full;
  border: 1px solid;
  border-color: inherit;
  background: $bg-black-primary;
  color: inherit;
  cursor: pointer;
}

@keyframes lostLife {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  60% {
    transform: scale(8) translateX(50%);
    opacity: 1;
  }
  100% {
    transform: scale(0) translateX(-20px) translateY(50px);
    opacity: 0;
  }
}

@keyframes gameOver {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    width: 140px;
  }
  98% {
    opacity: 0;
    visibility: hidden;
    width: 140px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}
</style>
