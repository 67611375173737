import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
//global css
import './styles/global.scss';

import timeago from 'vue-timeago3';
import { createPinia } from 'pinia';

const pinia = createPinia();
//creating App
const app = createApp(App);

app.use(pinia);
app.use(timeago);
//using vue router
app.use(router).mount('#app');
