import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGameStore = defineStore('game', {
  state: () => {
    return {
      generated: false,
      scored: false,
      loadingImage: false,
      loading_score: false,
      shakeTrigger: false,
      survived: false,
      seed: '1289259888159195188',
      userInput: '',
      status: GameStatus.INITIAL,
      roundStatus: RoundStatus.INITIAL,
      finalLevel: 5
    };
  },
  actions: {
    startGame() {
      this.status = GameStatus.InProgress;
    },
    resetGameStateForNewGame() {
      this.$reset();
      this.roundStatus = RoundStatus.INITIAL;
    },
    resetGameStateForNextRound() {
      this.$reset();
      this.status = GameStatus.InProgress;
    },
    submitResponse() {
      this.scored = false;
      this.roundStatus = RoundStatus.SUBMITTED;
      this.loadingImage = true;
    },
    updateWithResults(survived: boolean) {
      this.scored = true;
      this.loadingImage = false;
      this.survived = survived;
    },
    triggerShake() {
      this.shakeTrigger = true;
      setTimeout(() => (this.shakeTrigger = false), 820); // Duration of shake animation
    }
  }
});

export enum GameStatus {
  INITIAL,
  InProgress,
  Surrendered,
  WON,
  LOST
}

export enum RoundStatus {
  INITIAL,
  SUBMITTED,
  EXPIRED,
  RUNNING
}

export const currentRequests = ref(0);

export const MAX_CONCURRENT_REQUESTS = 8;

export const remainingTime = ref(60);

export const inRetryForm = ref(false);
