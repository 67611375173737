<template>
  <transition name="slide-fade">
    <div v-if="visible" class="snackbar" :class="type">
      <div class="fuse"></div>
      {{ message }}
    </div>
  </transition>
</template>

<script setup>
const { visible, message, type } = defineProps({
  visible: Boolean,
  message: String,
  type: {
    type: String,
    default: 'error'
  }
});

const emit = defineEmits(['update:visible']);
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.error {
  background-color: #7f57d9;
}

.fuse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #7f57d9;
  animation: burnFuse 2s linear forwards;
}

.snackbar {
  position: fixed;
  left: 25%;
  bottom: 50px;
  transform: translate(-45%, -25%);
  width: 30%;
  height: min-content;
  padding-top: 20px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  background-color: #323232;
  color: white;
  border-radius: 4px;
  text-align: left;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  justify-self: flex-start;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 640px) {
  .snackbar {
    box-sizing: border-box;
    transform: translate(0%, -55%);
    width: 90%;
    left: 5%;
  }
}

@keyframes burnFuse {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
</style>
