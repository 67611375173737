<template>
  <img
    v-if="generatedImage"
    :src="generatedImage"
    alt="Generated
    Image"
    :class="{
      image: true,
      '--win': won,
      '--lose': lost
    }"
  />

  <div
    v-else-if="gameStore.loadingImage && !generatedImage"
    :class="{
      'generated-image__container': true,
      '--loading': gameStore.loadingImage && !generatedImage,
      '--win': won,
      '--lose': lost
    }"
  >
    <img
      :src="require('@/assets/paint-roll.svg')"
      alt="Painting..."
      class="loading-image"
    />
  </div>

  <img
    v-else
    src="@/assets/base_images/placeholder.svg"
    alt="Image Placeholder"
  />
</template>
<script setup lang="ts">
import { useGameStore } from '@/stores/gameStore';
import { useUserStore } from '@/stores/userStore';
import { computed } from 'vue';

defineProps({
  generatedImage: String
});

const gameStore = useGameStore();

const userStore = useUserStore();

const won = computed(
  () => userStore.currentRound?.survived && gameStore.scored
);

const lost = computed(
  () => !userStore.currentRound?.survived && gameStore.scored
);
</script>
<style scoped lang="scss">
img {
  @include phone {
    grid-area: image1;
  }
  @include image($imageheight, $imagewidth);
  grid-area: image2;

  &.--win {
    border: 4px solid $accent-green;
    border-radius: 16px;
  }

  &.--lose {
    border: 4px solid $accent-red;
    border-radius: 16px;
  }
}

.loading-image {
  animation: move-top-bottom 0.5s infinite;

  max-height: 64px;
}

@keyframes move-top-bottom {
  0% {
    transform: translate(0, 20px);
  }
  25% {
    transform: translate(0, 35px);
  }
  50% {
    transform: translate(0, 40px);
  }
  75% {
    transform: translate(0, 35px);
  }
  100% {
    transform: translate(0, 20px);
  }
}
.generated-image__container {
  background: $bg-black-alt;
  border-radius: 16px;
  width: calc(min(#{$imageheight}, #{$imagewidth}));
  height: calc(min(#{$imageheight}, #{$imagewidth}));
  display: flex;
  align-self: center;
  justify-self: center;
}

.image {
  img {
    @include phone {
      display: unset;
    }
  }
}
</style>
