// imageUtils.ts
import { ref } from 'vue';
import { useUserStore } from '@/stores/userStore';
import { getRandomElement } from './utilizatioHelper';
import baseImages from '@/assets/base_images/base_images.json';

const imageData = ref(baseImages.images);
export function selectAndLogNextImage() {
  const userStore = useUserStore();

  let prompt: string;
  let targetImage: string;

  const difficultyString = `difficulty_level_${userStore.difficulty_level}`;
  const imageUrls = Object.keys(imageData.value[difficultyString]);
  if (imageUrls.length > 0) {
    const randomImageUrl = getRandomElement(imageUrls);
    prompt = imageData.value[difficultyString][randomImageUrl];
    targetImage = require('@/assets/base_images/' + randomImageUrl);

    delete imageData.value[difficultyString][randomImageUrl];
  } else {
    targetImage = '';
  }
  userStore.logNewRound(targetImage, prompt);

  return targetImage;
}
