import { Dialog, DialogType } from '@/models/Dialog';
import { useUserStore } from '@/stores/userStore';
import dialogsJSON from '@/assets/images/game/dialog/dialogs.json';
import { useGameStore } from '@/stores/gameStore';
import { getRandomElement } from './utilizatioHelper';

let chosenNumbers = [];
const NUM_OF_DIALOGS = 8;

const positiveEmojis = [
  '😀',
  '😊',
  '😄',
  '😍',
  '😎',
  '😇',
  '😃',
  '🥰',
  '🥳',
  '😁',
  '😂',
  '🧐',
  '🙌',
  '👏',
  '🤝',
  '👍',
  '😻',
  '🤙',
  '🤘',
  '✌️',
  '🤞',
  '👀',
  '🧠',
  '💃',
  '☀️',
  '🏆',
  '🎖️',
  '🎉',
  '🎊'
];

const negativeEmojis = [
  '😢',
  '😞',
  '😔',
  '😓',
  '🙁',
  '😩',
  '😭',
  '💔',
  '👎',
  '😕',
  '🤯',
  '😭',
  '😨',
  '😵‍💫',
  '🥴',
  '🤡',
  '💩',
  '🙀',
  '👁️',
  '🌚',
  '🌪️',
  '💨',
  '☔️',
  '🌨️'
];
const artEmojis = [
  '🎨',
  '🖌️',
  '🖼️',
  '✏️',
  '📐',
  '✍️',
  '💎',
  '🎭',
  '🔥',
  '🌟',
  '💖',
  '🌈',
  '👨‍🎨'
];

export function getDialogID(type: DialogType): number {
  if (type === DialogType.LOST || type === DialogType.WON) {
    return 1;
  }
  if (chosenNumbers.length === NUM_OF_DIALOGS) {
    chosenNumbers = [];
  }

  let randomNumber: number;
  do {
    randomNumber = Math.floor(Math.random() * NUM_OF_DIALOGS - 1) + 1;
  } while (chosenNumbers.includes(randomNumber));

  chosenNumbers.push(randomNumber);

  return randomNumber;
}

export function getRandomEmojis(hasWon: boolean) {
  const randomPositiveEmoji = getRandomElement(
    hasWon ? positiveEmojis : negativeEmojis
  );
  const randomPositiveEmoji2 = getRandomElement(
    hasWon ? positiveEmojis : negativeEmojis
  );
  const randomArtEmoji = getRandomElement(artEmojis);

  return ` Points  ${randomPositiveEmoji} ${randomArtEmoji} ${randomPositiveEmoji2}`;
}

/**
 * determines the type of dialog that should be displayed based on the state of the game.
 * @param {boolean} isEnd - A boolean value indicating whether the game has ended or not.
 * @returns {DialogType} The type of the dialog. It returns one of the following values:
 * - DialogType.GAME: If the game has not ended yet.
 * - DialogType.WON: If the game has ended and the player has survived (won the game).
 * - DialogType.LOST: If the game has ended and the player has not survived (lost the game).
 */

export function getDialogType(isEnd: boolean): DialogType {
  const gameStore = useGameStore();
  if (!isEnd) {
    return DialogType.GAME;
  }
  return gameStore.survived ? DialogType.WON : DialogType.LOST;
}

export function getCharacterName(
  isEndView: boolean,
  dialog: Dialog,
  round: number
): string {
  const userStore = useUserStore();
  switch (round) {
    case 0:
      return dialog.name;
    case 1:
      return userStore.name;
    default:
      return isEndView ? dialog.name2 : dialog.name;
  }
}

export function getDialog(isEnd: boolean) {
  const type = getDialogType(isEnd);
  const dialogID = getDialogID(type);
  return dialogsJSON[`${type}`][`${dialogID}`];
}
