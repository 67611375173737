<template>
  <div :class="`game__copy-right --${props.location}`">
    <span
      >Made with ♥ by
      <a href="https://www.inovex.de/de/kontakt/" target="_blank">
        inovex</a
      ></span
    >
    <div :class="`copy-right__legal --${props.location}`">
      <a @click="$router.push(ROUTES.TEAM)">Team</a>
      <a href="https://www.inovex.de/en/datenschutz/" target="_blank"
        >Data protection</a
      >
      <a href="https://www.inovex.de/en/impressum/" target="_blank"
        >Legal Notice</a
      >
      <a @click="$router.push(ROUTES.DISCLAIMER)">Disclaimer</a>
      <div :class="`tooltip --${props.location}`">
        <IconComponent
          :showTitle="false"
          :class="{
            'icon-traffic': true,
            '--too-high': currentRequests >= MAX_CONCURRENT_REQUESTS
          }"
        >
          <IconSpeedometer></IconSpeedometer>
        </IconComponent>
        <span
          class="tooltiptext"
          :class="{ '--too-high': currentRequests >= MAX_CONCURRENT_REQUESTS }"
          >increased data traffic</span
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ROUTES } from '@/router/routes';
import IconComponent from '@/components/IconComponent.vue';
import IconSpeedometer from '@/assets/VueIcons/IconSpeedometer.vue';
import { currentRequests, MAX_CONCURRENT_REQUESTS } from '@/stores/gameStore';
import { updateCurrentRequests } from '@/utils/utilizatioHelper';
const props = defineProps({
  location: {
    type: String
  }
});
setInterval(updateCurrentRequests, 5000);
</script>

<style lang="scss">
.game__copy-right {
  display: flex;
  margin: auto;
  justify-content: space-between;
  margin-top: 16px;
  color: $text-tertiary;
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  width: 90dvw;
  max-width: 2000px;
  z-index: 5;

  a {
    color: $text-tertiary;
    font-weight: 700;
    text-decoration: none;
  }

  @include phone {
    justify-self: end;
    font-size: 0.5em;
    margin-top: 0;
    align-self: center;
    padding-top: 10px;
    bottom: 10px;
  }
}
.game__copy-right.--start {
  flex-direction: column;
  justify-content: center;
  @include phone {
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 24px;
    transform: translateX(-50%);
    left: 50%;
  }
}

.game__copy-right.--play {
  flex-direction: row;
  margin-top: 64px;
  position: absolute;
  top: calc(90dvh - 24px);
  transform: translateX(-50%);
  left: 50%;
  @include phone {
    top: unset;
    bottom: 24px;
  }
}

.game__copy-right.--end {
  flex-direction: row;
  margin-top: 64px;
  justify-content: space-between;
  position: absolute;
  top: calc(90dvh - 24px);
  transform: translateX(-50%);
  left: 50%;

  @include phone {
    top: unset;
    bottom: 24px;
  }
}

.copy-right__legal {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-left: 30%;

  a {
    cursor: pointer;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }
}

.copy-right__legal.--start {
  flex-direction: column;
  margin-top: $spacing-3xl;
  gap: $spacing-xl;
  margin-left: 0%;

  @include laptop {
    margin-top: $spacing-xl;
    gap: $spacing-md;
  }

  @include phone {
    flex-direction: row;
    margin-top: unset;
  }
}

.copy-right__legal.--play {
  flex-direction: row;
  gap: 32px;

  @include laptop {
    margin-left: 0;
  }
}

.copy-right__legal.--end {
  flex-direction: row;
  gap: 32px;

  @include laptop {
    margin-left: 0;
  }
}

.icon-traffic {
  display: flex;
  color: #94969c;
  &.--too-high {
    color: #f04438;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip.--start {
  margin-top: 16px;
  margin-left: 0px;

  @include laptop {
    margin-top: 8px;
  }
}

.tooltiptext {
  visibility: hidden;
  background-color: #161b26;
  color: #f5f5f6;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  width: 120px;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  z-index: 1;
}

.tooltip.--start .tooltiptext {
  visibility: hidden;
  position: relative;
  z-index: 1;
  margin-left: 40px;
  top: -21px;
  left: 0%;
  bottom: 0%;
}

.tooltip:hover .tooltiptext.--too-high {
  visibility: visible;
}
</style>
