<template>
  <g id="award-04">
    <path
      id="Icon"
      d="M7.39557 10.9131L3.91638 6.5641C3.6998 6.29337 3.59151 6.15801 3.5145 6.00728C3.44618 5.87355 3.39625 5.7312 3.36606 5.5841C3.33203 5.41829 3.33203 5.24494 3.33203 4.89824V4.33366C3.33203 3.40024 3.33203 2.93353 3.51369 2.57701C3.67348 2.2634 3.92844 2.00844 4.24205 1.84865C4.59857 1.66699 5.06528 1.66699 5.9987 1.66699H13.9987C14.9321 1.66699 15.3988 1.66699 15.7553 1.84865C16.069 2.00844 16.3239 2.2634 16.4837 2.57701C16.6654 2.93353 16.6654 3.40024 16.6654 4.33366V4.89824C16.6654 5.24494 16.6654 5.41829 16.6313 5.5841C16.6011 5.7312 16.5512 5.87355 16.4829 6.00728C16.4059 6.15801 16.2976 6.29337 16.081 6.5641L12.6018 10.9131M4.16541 2.50032L9.99874 10.0003L15.832 2.50032M12.945 11.2207C14.5722 12.8479 14.5722 15.4861 12.945 17.1133C11.3178 18.7405 8.67961 18.7405 7.05242 17.1133C5.42524 15.4861 5.42524 12.8479 7.05242 11.2207C8.6796 9.59353 11.3178 9.59353 12.945 11.2207Z"
      stroke="#CECFD2"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>
