import { APIResponse } from '@/models/APIResponse';
import { handleGameAPIResponse } from '@/utils/apicalls';
import { extractRequestCount } from '@/utils/utilizatioHelper';
import axios from 'axios';

const BASE_URL = process.env.VUE_APP_GAME_BACKEND_URL + '/active_request_count';

export async function fetchUtilization(): Promise<APIResponse<number>> {
  return await handleGameAPIResponse(
    axios.get(BASE_URL, { timeout: 9000 }),
    extractRequestCount
  );
}
