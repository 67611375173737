<template>
  <!--
Progress Circle component. This component is used to display a circular progress bar with a score in the center.
The progress bar is created using two SVG circles, one for the track and one for the progress.
The score is displayed using an SVG text element.
-->

  <div class="progress-circle">
    <svg :width="size" :height="size">
      <circle
        :cx="radius"
        :cy="radius"
        :r="radius - strokeWidth"
        :stroke="trackColor"
        :stroke-width="strokeWidth"
        fill="none"
      ></circle>
      <circle
        :cx="radius"
        :cy="radius"
        :r="radius - strokeWidth"
        :stroke="progressColor"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        fill="none"
        stroke-linecap="round"
        :transform="`rotate(-90 ${radius} ${radius})`"
      ></circle>
      <text
        x="50%"
        :y="!isDesktop() ? '31%' : '35%'"
        text-anchor="middle"
        dominant-baseline="central"
        fill="white"
      >
        <tspan
          :x="!isDesktop() ? '54%' : '50%'"
          :dy="!isDesktop() ? '15px' : '0px'"
          fill="#94969C"
          :font-size="!isDesktop() ? '12' : '24'"
        >
          Total Points
        </tspan>
        <tspan
          :x="!isDesktop() ? '53%' : '50%'"
          :dy="!isDesktop() ? '30px' : '60px'"
          fill="white"
          :font-size="!isDesktop() ? '30px' : '60px'"
        >
          {{ score }}
        </tspan>
      </text>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { isDesktop } from '@/utils/responsiveHelper';
import { computed } from 'vue';

const props = defineProps({
  score: Number,
  size: {
    type: Number,
    default: !isDesktop() ? 150 : 250
  },
  strokeWidth: {
    type: Number,
    default: 14
  },
  trackColor: {
    type: String,
    default: '#1f242f'
  },
  progressColor: {
    type: String,
    default: '#9E77ED'
  }
});

const radius = computed(() => (props.size + props.strokeWidth / 2) / 2);
const circumference = computed(() => 2 * Math.PI * radius.value);
const dashOffset = computed(
  () => circumference.value * (1 - props.score / 500)
);
</script>

<style setup lang="scss">
.progress-circle {
  display: inline-block;
  position: relative;
}
</style>
