<template>
  <div class="level-info">
    <div class="level-info__level">
      Level
      {{ userStore.level }}
    </div>

    <BadgeComponent>
      <Vue3autocounter
        :startAmount="scores.previous"
        :endAmount="scores.current"
        :duration="1.5"
        separator=""
        :autoinit="true"
        suffix=" Points"
      />
    </BadgeComponent>
  </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';
import Vue3autocounter from 'vue3-autocounter';
import { useUserStore } from '@/stores/userStore';
import BadgeComponent from '@/components/BadgeComponent.vue';

const userStore = useUserStore();

const scores = reactive({
  current: userStore.score,
  previous: userStore.score
});

watch(
  () => userStore.score,
  (newScore, previousScore) => {
    scores.current = newScore;
    scores.previous = previousScore || 0;
  }
);
</script>

<style scoped lang="scss">
.level-info {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  align-items: center;

  @include phone {
    flex-direction: column;
    align-items: start;
  }
}

.level-info__level {
  color: $text-primary;
  text-wrap: nowrap;
  @include text-lg_semibold;
}
</style>
