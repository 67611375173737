export enum ROUTES {
  PLAY = '/game/play',
  DIALOG = '/game/dialog',
  END_DIALOG = '/game/dialog/end',
  GAME_OVER = '/end',
  LEADERBOARD = '/end/table',
  ANALYSIS = '/end/analysis',
  ANALYSIS_MOBILE = '/end/analysis-mobile',
  END_MENU = '/end/menu',
  START = '/',
  TEAM = '/team',
  DISCLAIMER = '/disclaimer'
}
