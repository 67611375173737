<template>
  <RetryForm v-if="showForm" />
  <RoundPoints v-else />
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

import RetryForm from './RetryForm.vue';
import RoundPoints from './RoundPoints.vue';

import { RoundStatus, useGameStore } from '@/stores/gameStore';
import { handleGameOver, isGameOver } from '@/utils/roundEndHelper';

const showForm = ref(false);
const gameStore = useGameStore();

let timer: number;

function startTimer() {
  timer = setInterval(() => {
    if (isGameOver()) {
      handleGameOver();
    } else {
      showForm.value = true;
    }
  }, 4000);
}

function stopTimer() {
  clearInterval(timer);
}

onMounted(() => {
  if (
    gameStore.userInput === '' &&
    gameStore.roundStatus === RoundStatus.EXPIRED
  ) {
    showForm.value = true;
  } else {
    startTimer();
  }
});
onBeforeUnmount(stopTimer);
</script>
