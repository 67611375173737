<template>
  <GameHeader />

  <DialogImage
    :imageValue="getCharacterImage"
    firstImage
    :class="{
      '--active': !isForgerSpeaking || isPhone()
    }"
  />

  <DialogImage
    :imageValue="getForgerImage()"
    :class="{ '--active': isForgerSpeaking, secondImage: true }"
  />

  <div class="game-dialog__dialog">
    <Transition name="fade" mode="out-in">
      <p class="game-dialog__dialog-speaker" :key="characterName">
        {{ getCharacterName(isEndView, dialog, round) }}
      </p>
    </Transition>
    <Transition name="fade" mode="out-in">
      <div style="text-align: center" :key="text">
        <ShowWordByWord
          :text="text"
          @ShowWordByWord:finished="showNextDialog"
        />
      </div>
    </Transition>
    <div class="dialog__skip-intro">
      <a @click="skipButtonRoute()">Skip dialog</a>
    </div>
  </div>

  <div class="dialog__countdown" v-if="showCountDown">
    <StartRoundCountdown />
  </div>
</template>

<script setup lang="ts">
import { getCharacterName, getDialog } from '@/utils/dialogHelper';
import DialogImage from '@/features/DialogImage.vue';
import StartRoundCountdown from '@/features/StartRoundContdown.vue';
import { computed, ref } from 'vue';
import router from '@/router';
import GameHeader from '@/features/GameHeader.vue';
import ShowWordByWord from '@/components/ShowWordByWord.vue';
import { useRoute } from 'vue-router';

import { ROUTES } from '@/router/routes';
import { isPhone } from '@/utils/responsiveHelper';
import { useGameStore } from '@/stores/gameStore';

const gameStore = useGameStore();
const route = useRoute();
const isEndView = Boolean(route.params.isEnd);

const dialog = getDialog(isEndView);

const round = ref(0);
const showCountDown = ref(false);

const text = ref(dialog.dialog[0]);

const isForgerSpeaking = computed(() => round.value === 1);

const getCharacterImage = computed(() => {
  if (!isPhone()) {
    if (isEndView && round.value === 2) {
      return dialog.characterImg2;
    } else {
      return dialog.characterImg;
    }
  } else {
    switch (round.value) {
      case 0:
        return dialog.characterImg;
      case 1:
        return undefined;
      case 2:
        return isEndView ? dialog.characterImg2 : dialog.characterImg;
      case 3:
        return dialog.forgerImg;
      default:
        return undefined;
    }
  }
});

const characterName = computed(() =>
  getCharacterName(isEndView, dialog, round.value)
);

const getForgerImage = () => {
  return isEndView &&
    ((round.value === 2 && !gameStore.survived) ||
      (round.value >= 1 && gameStore.survived))
    ? dialog.forgerImg
    : undefined;
};

const showNextDialog = () => {
  window.setTimeout(() => {
    if (round.value < 2) {
      updateDialogSpeaker();
    } else if (!isEndView) {
      showCountDown.value = true;
    } else {
      router.push(ROUTES.GAME_OVER);
    }
  }, 1000);
};

function updateDialogSpeaker() {
  round.value++;

  text.value = dialog.dialog[round.value];
}

function skipButtonRoute() {
  if (isEndView) {
    router.push(ROUTES.GAME_OVER);
  } else {
    router.push(ROUTES.PLAY);
  }
}
</script>

<style scoped lang="scss">
.--active {
  border-radius: 16px;
  border: 1px solid $primary-violett-border;
  background: $bg-black-alt;

  /* Focus rings/ring-brand-shadow-xs */
  box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  @include pulsate;
}

.game-dialog__dialog {
  display: flex;
  height: 180px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-xl;
  align-self: stretch;
  grid-area: footer;
}
.game-dialog__dialog-speaker {
  color: $text-tertiary;
  text-align: center;
  @include text-md_regular;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.secondImage {
  @include phone {
    display: none;
  }
}

.dialog__skip-intro {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  width: 100%;
  gap: 12px;
  margin-top: auto;

  a {
    color: $text-tertiary;
    cursor: pointer;
    font-weight: 400;
    padding: 11px;
  }
}
.dialog__countdown {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bg-black-alt;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100%;
  z-index: 99998;
  color: $text-primary;
  font-size: 50px;
  text-align: center;
}
</style>
