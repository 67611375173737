import { DeviceType } from '@/models/DeviceType';

export function checkDeviceType() {
  const screenWidth = window.innerWidth;
  const isPhone = screenWidth < 640;
  const isTablet = screenWidth >= 640 && screenWidth < 1024;
  const isDesktop = screenWidth >= 1024;

  if (isPhone) {
    return DeviceType.PHONE;
  } else if (isTablet) {
    return DeviceType.TABLET;
  } else return DeviceType.DESKTOP;
}

export function isPhone() {
  const screenWidth = window.innerWidth;

  return screenWidth < 640;
}

export function isDesktop() {
  const screenWidth = window.innerWidth;

  return screenWidth >= 2024;
}

export function isKeyboardExpanded() {
  const MIN_KEYBOARD_HEIGHT = 300; // N.B.! this might not always be correct

  return (
    isPhone() &&
    window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height
  );
}
