<template>
  <EndLayoutComponent>
    <EndHeader title="Hall of Fame" class="header" />

    <div class="leaderboard-container" ref="tableContainer">
      <ul class="leaderboard-list" v-if="leaderboard.errorOccurred === false">
        <li
          v-for="(entry, index) in currentLeaderboardPage"
          :key="index"
          :class="{
            'leaderboard-entry': true,
            winner: currentPage === 1 && index === 0,
            'top-three': placement[index] !== 1 && placement[index] <= 3,
            'current-user': currentUserPosition === placement[index]
          }"
        >
          <span class="index_col">
            <span
              :class="{
                index: true,
                winner: placement[index] === 1,
                'top-three': placement[index] !== 1 && placement[index] <= 3
              }"
              >{{ placement[index] }}.</span
            >
          </span>
          <TrophyCol class="trophy-col" :placement="placement[index]" />
          <span class="name">
            <span>{{ entry[1] }}</span>
            <span>{{ nameDescription[placement[index] - 1] }}</span>
          </span>
          <timeago class="date" :datetime="new Date(entry[4] + ' UTC')" />
          <span class="bold points-col">{{ convertToSuffix(entry[3]) }}</span>
        </li>
        <PaginationComponent
          v-if="!isPhone()"
          :currentPage="currentPage"
          :totalPages="totalPages"
          :onNext="() => (currentPage = Math.min(totalPages, currentPage + 1))"
          :onBack="() => (currentPage = Math.max(1, currentPage - 1))"
        />
      </ul>
      <ul v-else>
        <h1 class="title">{{ leaderboard.errorMessage }}</h1>
      </ul>
      <PaginationComponent
        v-if="isPhone() & (leaderboard.errorOccurred === false)"
        :currentPage="currentPage"
        :totalPages="totalPages"
        :onNext="() => (currentPage = Math.min(totalPages, currentPage + 1))"
        :onBack="() => (currentPage = Math.max(1, currentPage - 1))"
      />
    </div>
  </EndLayoutComponent>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
import { getLeaderboard } from '@/services/leaderboardService';
import { useUserStore } from '@/stores/userStore';
import EndLayoutComponent from '@/components/EndLayoutComponent.vue';
import EndHeader from '@/features/EndHeader.vue';
import { isPhone } from '@/utils/responsiveHelper';

import {
  getRandomDesciption,
  getPlacement,
  convertToSuffix
} from '@/utils/tableHelper';
import TrophyCol from '@/features/TrophyCol.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';

const userStore = useUserStore();

const currentPage = ref(1);
const windowHeight = ref(window.innerHeight);
const tableContainer = ref(null);
const placement = ref([]);

const calculatePlacement = () => {
  placement.value = currentLeaderboardPage.value.map((entry, index) => {
    return getPlacement(currentPage.value, entriesPerPage.value, index);
  });
};

const entriesPerPage = computed(() => {
  const heightPerItem = isPhone() ? 72 : 61.25;

  const availableHeight = tableContainer.value?.clientHeight || 500;

  return Math.max(1, Math.floor(availableHeight / heightPerItem) - 1);
});

// Function to update window width
const onResize = () => {
  windowHeight.value = window.innerHeight;
};

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

const leaderboard = await getLeaderboard();
const nameDescription = getRandomDesciption();
const leaderboardData = ref(leaderboard.data);
const currentUserPosition =
  leaderboard.data?.findIndex((element) => element[0] === userStore.id) + 1;
const totalPages = computed(() =>
  Math.ceil(leaderboard.data.length / entriesPerPage.value)
);

const currentLeaderboardPage = computed(() => {
  const start = (currentPage.value - 1) * entriesPerPage.value;
  const end = start + entriesPerPage.value;
  return leaderboardData.value.slice(start, end);
});

watch(
  () => currentPage.value,
  () => {
    calculatePlacement();
  }
);

onMounted(() => {
  calculatePlacement();
});
</script>
<style scoped lang="scss">
.leaderboard-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: header-end;
  grid-row-end: 4;
}

.leaderboard-list {
  height: 100%;
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid $bg-disabled;
  background: $bg-black-primary;

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  @include phone {
    overflow-x: scroll;
    margin: 0;
    width: 100%;
    background: transparent;
    border: 0px solid $bg-disabled;
  }

  li:last-child {
    &.pagination {
      margin-top: auto;
    }
  }
}

.leaderboard-entry {
  display: flex;
  color: $text-tertiary;
  height: 60px;
  font-size: 0.875rem;
  width: 100%;
  @include text-sm_regular;
  align-items: center;

  border-bottom: 1px solid $bg-disabled;
  transition: background-color 0.1s ease-in;

  @include phone {
    height: 72px;
    border-bottom: none;
  }

  &.winner {
    color: $accent-green;

    > * {
      &.bold {
        font-weight: 600;
        color: $accent-green;
      }
    }
  }

  &.top-three {
    color: $brand-700;
    > * {
      &.bold {
        font-weight: 600;
        color: $brand-700;
      }
    }
  }

  &.current-user {
    background-color: $primary-violett;
  }

  > * {
    display: inline-flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding: 16px 24px;

    @include phone {
      padding: 8px 12px;
    }

    &.bold {
      font-weight: 600;
      color: white;
    }
  }

  &.pagination {
    border-bottom: none;
  }

  .entry-name {
    flex: 2;
  }

  > span:first-child {
    max-width: 50px;
    text-align: center;
    justify-content: center;
    &.winner {
      color: $accent-green;
    }
  }
}

.index {
  border-radius: $radius-full;
  border: 1px solid $text-secondary;
  width: 30px;
  height: 30px;
  &.winner {
    border-color: $accent-green;
  }

  &.top-three {
    border-color: $brand-700;
  }

  @include phone {
    border: none;
    margin: 0;
  }
}

.index_col {
  margin: 16px 24px;
  flex: unset;
  width: 30px;
  height: 30px;
  @include phone {
    flex: 1 0 30px;
    margin: 0;
    height: 56px;
    border-bottom: 1px solid $bg-disabled;
  }

  > * {
    display: inline-flex;
    align-items: center;
    flex: 1;
    justify-content: center;
  }
}

.trophy-col {
  @include phone {
    flex: 1 0 30px;
    height: 56px;
    margin-bottom: 0;
    border-bottom: 1px solid $bg-disabled;
  }
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span:first-child {
    color: white;
  }

  > span:last-child {
    color: $text-tertiary;
  }

  @include phone {
    flex: 1 0 240px;
    height: 56px;
    border-bottom: 1px solid $bg-disabled;
  }
}

.date {
  @include phone {
    flex: 1 0 120px;
    height: 56px;
    border-bottom: 1px solid $bg-disabled;
  }
}

.points-col {
  @include phone {
    flex: 1 0 30px;
    height: 56px;
    border-bottom: 1px solid $bg-disabled;
  }
}

.header {
  grid-area: header;
}
</style>
