import axios from 'axios';
import { Buffer } from 'buffer';

export function createBase64ImageString(imageData: string): string {
  return `data:image/jpeg;base64,${imageData}`;
}

export async function getBase64Img(origImageLink: string) {
  const imgUrl = window.location.href.replace('/game/play', '') + origImageLink;
  return await axios
    .get(imgUrl, {
      responseType: 'arraybuffer'
    })
    .then((response) =>
      Buffer.from(response.data, 'binary').toString('base64')
    );
}
