import { negative_prompts } from '@/assets/negative_prompts';
import { APIResponse } from '@/models/APIResponse';
import { useGameStore } from '@/stores/gameStore';
import { handleGameAPIResponse } from '@/utils/apicalls';
import { createBase64ImageString } from '@/utils/imageHelper';
import axios from 'axios';

const PROMPT_DEFAULT = {
  negative_prompt: negative_prompts,
  height: 768,
  width: 768
};
const BASE_URL = process.env.VUE_APP_GAME_BACKEND_URL + '/txt2img';

export async function postPromptText(): Promise<APIResponse<string>> {
  const { userInput, seed } = useGameStore();
  const data = JSON.stringify({
    prompt: userInput,
    seed,
    ...PROMPT_DEFAULT
  });

  return await handleGameAPIResponse(
    axios.post(BASE_URL, data, {
      timeout: process.env.VUE_APP_TIMEOUT_TXT2IMG
    }),
    createBase64ImageString
  );
}
