const TITLE_DESC = [
  'Potato, po-tah-to',
  'Who?',
  'aka Wayne Interessiert’s',
  'Yeah, whatever ...',
  'Come again?',
  'Second ist the first looser',
  'One in a million',
  'Artistic mastermind or just a good copycat?',
  'The Picasso of forgeries',
  'Creating art or imitating it?',
  'A brush with deception',
  'Artful dodger or genuine talent?',
  'A canvas of secrets',
  'Forgery or fine art? You be the judge.',
  "Mona Lisa smiles, forger's brush runs wild",
  'The art of illusion',
  'Faking it till you make it',
  'The art of deception',
  'Brushstrokes of intrigue',
  'Imitation or innovation?',
  'A canvas of mystery',
  'Forgery or finesse?',
  'Unveiling the artful dodgers',
  'The enigma of art forgery',
  'A stroke of deception',
  'Master forger or misunderstood artist?',
  'The illusion of authenticity',
  'In the shadow of the masters',
  'The art of mimicry',
  'The mystery behind the brush',
  'Artful duplicity',
  'A web of artistic deceit',
  'The imitation game',
  'The art of counterfeiting',
  'Brushes with deception',
  'A masterpiece of mimicry',
  'Unmasking the art forgers',
  'The allure of artifice',
  'Forgery or finesse?',
  "The artful dodgers' gallery",
  'A canvas of contradictions',
  'The mystery of the copied masterpiece',
  'Unveiling the counterfeit creators',
  'The craft of imitation',
  'Artful mimicry',
  'The intrigue of art forgery',
  'The enigmatic art of fakes',
  'The illusion of originality',
  'The shadowy world of art fraud'
];

export const getRandomDesciption = () => {
  return shuffleArray(TITLE_DESC);
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const getPlacement = (
  currentPage: number,
  entriesPerPage: number,
  index: number
): number => (currentPage - 1) * entriesPerPage + (index + 1);

export function convertToSuffix(num) {
  if (num < 1e3) {
    return num.toString();
  } else if (num < 1e6) {
    return (num / 1e3).toFixed(2).replace(/\.0$/, '') + 'K';
  } else if (num < 1e9) {
    return (num / 1e6).toFixed(2).replace(/\.0$/, '') + ' Mio.';
  } else if (num < 1e12) {
    return (num / 1e9).toFixed(2).replace(/\.0$/, '') + ' Mrd.';
  } else return 'cheater 🫢😨 ';
}
