<template>
  <path
    id="Icon"
    d="M15.8327 10.0003H4.16602M4.16602 10.0003L9.99935 15.8337M4.16602 10.0003L9.99935 4.16699"
    stroke="#CECFD2"
    stroke-width="1.66667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
