<template>
  <EndLayoutComponent>
    <div class="content">
      <h1>Parrot Team</h1>
      <div class="card-container">
        <div class="card">
          <img src="@/assets/images/team/pascal.jpg" alt="" />
          <div class="text-box">
            <h2>Pascal</h2>
            <div>Product Owner</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/marie.jpg" alt="" />
          <div class="text-box">
            <h2>Marie-Kristin</h2>
            <div>Product Owner</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/sebastian.jpg" alt="" />
          <div class="text-box">
            <h2>Sebastian</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/yuliia.jpg" alt="" />
          <div class="text-box">
            <h2>Yuliia</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/nicolas.jpg" alt="" />
          <div class="text-box">
            <h2>Nicolas</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/irma.jpg" alt="" />
          <div class="text-box">
            <h2>Irma</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/tianran.jpg" alt="" />
          <div class="text-box">
            <h2>Tianran</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/felix.jpg" alt="" />
          <div class="text-box">
            <h2>Felix</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/johannes.jpg" alt="" />
          <div class="text-box">
            <h2>Johannes</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/nico.jpg" alt="" />
          <div class="text-box">
            <h2>Nico</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/lennart.jpg" alt="" />
          <div class="text-box">
            <h2>Lennart</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/carina.jpg" alt="" />
          <div class="text-box">
            <h2>Carina</h2>
            <div>Working student</div>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/team/alex.jpg" alt="" />
          <div class="text-box">
            <h2>Alex</h2>
            <div>Working student</div>
          </div>
        </div>
      </div>
    </div>
  </EndLayoutComponent>
</template>
<script lang="ts">
import EndLayoutComponent from '@/components/EndLayoutComponent.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TeamPage',
  created() {
    document.title = 'Team | inovex Parrot';
  },
  components: { EndLayoutComponent }
});
</script>
<style scoped lang="scss">
.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
}
.card {
  border: 1px solid $text-tertiary;
  width: 100%;
  min-width: 90px;
  max-width: 200px;
  border-radius: 17px;
  box-shadow: 0 3px 10px rgba($bg-black-primary, 0.16);
  margin-bottom: 20px;
  margin-right: 20px;
  flex: 1 0 auto;
}

.card img {
  filter: grayscale(100%);
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 17px 17px 0 0;
}

.text-box {
  padding-left: 17px;
  padding-bottom: 17px;
}

.text-box h2 {
  color: $text-primary;
}

.text-box div {
  color: $text-tertiary;
}

@media only screen and (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* Large devices (large laptops and desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

.content {
  flex: 0 0 80%;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
</style>
