<template>
  <EndLayoutComponent v-if="rounds && rounds.length > 0">
    <EndHeader title="Game Analysis" class="header"> </EndHeader>
    <div class="level-box">
      <button
        :key="lvl"
        v-for="(round, lvl) in rounds"
        @click="changeRound(lvl)"
        :disabled="activeRound == lvl"
      >
        Lvl {{ lvl + 1 }}
      </button>
    </div>
    <div class="flip-box">
      <div class="flip-box-inner">
        <div>
          <div v-if="preGenerated">
            <img
              :src="rounds[activeRound].preGeneratedImage.src"
              class="image"
              alt="target-image"
            />
            <p class="prompts-mobile">
              {{ rounds[activeRound]?.preGeneratedImage.title }}
            </p>
          </div>
          <div v-else>
            <div class="generated-image-container__mobile">
              <div class="image-info">
                <p>Attempt {{ activeTry }}</p>
                <BadgeComponent
                  :variant="currentRound?.survived ? 'success' : 'error'"
                  :withBadge="false"
                  >{{ currentRound?.score }} Points</BadgeComponent
                >
              </div>
              <img
                :src="getImageSource(currentRound?.src)"
                alt="generatedImage"
                class="image genImg"
                :class="{
                  '--won': currentRound?.survived
                }"
              />
            </div>
            <p class="prompts-mobile">
              {{ currentRound?.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button @click="changeTry(activeTry - 1)" :disabled="activeTry == 0">
        <IconComponent>
          <IconArrowLeft />
        </IconComponent>
      </button>
      <button
        @click="changeTry(activeTry + 1)"
        :disabled="activeTry == currentRoundLength"
      >
        <IconComponent>
          <IconArrowRight />
        </IconComponent>
      </button>
    </div>
  </EndLayoutComponent>
  <EndLayoutComponent v-else>
    <EndHeader title="Game Analysis" class="header"> </EndHeader>
    <div>No rounds played yet</div>
  </EndLayoutComponent>
</template>

<script setup lang="ts">
import BadgeComponent from '@/components/BadgeComponent.vue';
import EndHeader from '@/features/EndHeader.vue';
import EndLayoutComponent from '@/components/EndLayoutComponent.vue';
import { useUserStore } from '@/stores/userStore';
import { computed, ref } from 'vue';
import IconComponent from '@/components/IconComponent.vue';
import IconArrowLeft from '@/assets/VueIcons/IconArrowLeft.vue';
import IconArrowRight from '@/assets/VueIcons/IconArrowRight.vue';

const userStore = useUserStore();
const activeRound = ref(0);
const activeTry = ref(0);
const preGenerated = ref(true);
const rounds = userStore.rounds.filter(
  (singleRound) => singleRound.generatedImages.length > 0
);

const currentRound = computed(
  () => rounds[activeRound.value]?.generatedImages[activeTry.value - 1]
);

const currentRoundLength = computed(
  () => Object.keys(rounds[activeRound.value]?.generatedImages).length
);

const changeRound = (index: number) => {
  preGenerated.value = true;
  activeRound.value = index;
  activeTry.value = 0;
};

const getImageSource = (src: string) => {
  return src.length > 5 ? src : require('@/assets/base_images/placeholder.svg');
};

const changeTry = (index: number) => {
  preGenerated.value = false;
  activeTry.value = index;
  if (index == 0) preGenerated.value = true;
};
</script>

<style lang="scss">
.header {
  grid-area: header;
}

.image {
  border-radius: 16px;
  @include image($imageWidthMobileEnd, $imageWidthMobileEnd);
}

.footer {
  grid-area: footer;
  display: flex;
  justify-content: center;
  gap: 16px;
  max-width: 360px;
  justify-self: center;
  width: 100%;
  align-self: end;
}

.footer button {
  @include secondaryButton;

  &:disabled {
    background-color: $bg-disabled;
  }
}

.prompts-mobile {
  @include text-md_regular;
  justify-self: center;
  text-align: start;
  width: 100%;
}

.generated-image-container__mobile {
  border-radius: 16px;
  display: flex;
  align-self: center;
  position: relative;
}

.image-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  left: 12px;
  top: 0px;
  p {
    @include text-md_regular;
  }
}

.genImg {
  border: 1px solid $accent-red;
  &.--won {
    border-color: $accent-green;
  }
}

.level-box {
  grid-area: image1; /*to be on top of images */
  display: flex;
  align-items: flex-start;
  height: 10%;
  gap: 5px;
}

.level-box button {
  @include secondaryButton;
  align-self: self-start;

  &:disabled {
    background-color: $bg-disabled;
  }
}

.flip-box {
  background-color: transparent;
  width: 100%;
  height: 75%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin-top: 60px; /* space for buttons */

  grid-area: image1;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-box-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-text {
  justify-self: flex-end;
  @include text-sm_regular;
  color: $text-secondary;
}

/* Style the back side */
.flip-box-back {
  transform: rotateY(180deg);
}
</style>
