import { defineStore } from 'pinia';

export const useKeyboardStore = defineStore({
  id: 'keyboard',
  state: () => ({
    isExpanded: false
  }),
  actions: {
    setExpanded(isExpanded: boolean) {
      this.isExpanded = isExpanded;
    }
  }
});
