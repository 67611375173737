<template>
  <g id="log-in-04" clip-path="url(#clip0_186_4042)">
    <path
      id="Icon"
      d="M10.5 7.6543L13.8333 10.9876M13.8333 10.9876L10.5 14.321M13.8333 10.9876H3M3.28152 6.82096C4.72239 4.33012 7.41549 2.6543 10.5 2.6543C15.1024 2.6543 18.8333 6.38526 18.8333 10.9876C18.8333 15.59 15.1024 19.321 10.5 19.321C7.41549 19.321 4.72239 17.6451 3.28152 15.1543"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      fill="none"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_186_4042">
      <rect width="20" height="20" transform="translate(0.5 0.987305)" />
    </clipPath>
  </defs>
</template>
