import { ROUTES } from '@/router/routes';
import { GameStatus, RoundStatus, useGameStore } from '@/stores/gameStore';
import { useUserStore } from '@/stores/userStore';
import router from '@/router';
import { postLeaderboard } from '@/services/leaderboardService';

export function replayRoundWithSameImage() {
  const userStore = useUserStore();
  const gameStore = useGameStore();

  userStore.resetCurrentRound();
  gameStore.resetGameStateForNextRound();
}

export function retryLevel() {
  const gameStore = useGameStore();
  const userStore = useUserStore();

  gameStore.resetGameStateForNextRound();
  userStore.currentRound = undefined;
  router.push(ROUTES.DIALOG);
}

export function playNextLevel() {
  const userStore = useUserStore();
  const gameStore = useGameStore();

  userStore.levelUp();
  gameStore.resetGameStateForNextRound();

  router.push(ROUTES.DIALOG);
}

export async function handleGameOver() {
  const userStore = useUserStore();
  router.push({ path: ROUTES.END_DIALOG });
  const leaderboardResponse = await postLeaderboard(
    userStore.name,
    userStore.level,
    userStore.score
  );

  userStore.id = leaderboardResponse.data;
}

export function useHandleRoundPlayed() {
  const userStore = useUserStore();
  const gameStore = useGameStore();
  if (userStore.lives === 0) {
    gameStore.status = GameStatus.LOST;
  } else if (!gameStore.survived) {
    useResetViewForNextTry();
  } else if (userStore.level === gameStore.finalLevel) {
    gameStore.status = GameStatus.WON;
  }
}

export function isGameOver(): boolean {
  const userStore = useUserStore();
  const gameStore = useGameStore();

  return (
    userStore.lives === 0 ||
    (userStore.level === gameStore.finalLevel &&
      userStore.currentRound.survived)
  );
}

export function useResetViewForNextTry() {
  const gameStore = useGameStore();
  const userStore = useUserStore();

  window.setTimeout(() => {
    gameStore.roundStatus = RoundStatus.INITIAL;
    userStore.currentRound = undefined;
  }, 1000);
}
