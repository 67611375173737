import { APIResponse } from '@/models/APIResponse';
import { handleGameAPIResponse } from '@/utils/apicalls';
import axios from 'axios';

const BASE_URL = process.env.VUE_APP_GAME_BACKEND_URL + '/leaderboard';

export async function getLeaderboard(): Promise<APIResponse<Array<any>>> {
  return await handleGameAPIResponse(
    axios.get(BASE_URL, {
      timeout: 9000
    })
  );
}

export async function postLeaderboard(
  player_name: string,
  player_level: number,
  player_score: number
): Promise<APIResponse<number>> {
  const data = {
    name: player_name,
    level: player_level,
    score: player_score
  };

  return await handleGameAPIResponse(
    axios.post(BASE_URL, data, {
      timeout: 9000
    })
  );
}

export async function patchLeaderboard(
  player_id: number,
  player_level: number,
  player_score: number
): Promise<APIResponse> {
  const data = {
    player_id: player_id,
    level: player_level,
    score: player_score
  };

  return await handleGameAPIResponse(
    axios.patch(BASE_URL, data, {
      timeout: 9000
    })
  );
}
