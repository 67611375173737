import { APIResponse } from '@/models/APIResponse';
import { useGameStore } from '@/stores/gameStore';
import { useUserStore } from '@/stores/userStore';
import { handleGameAPIResponse } from '@/utils/apicalls';
import { getBase64Img } from '@/utils/imageHelper';
import axios from 'axios';

const BASE_URL = process.env.VUE_APP_GAME_BACKEND_URL + '/compare_images';

export async function postImageForScoring(
  origImageLink: string,
  generatedImage: string
): Promise<APIResponse> {
  const origImageBase64 = await getBase64Img(origImageLink);
  const gameStore = useGameStore();
  const userStore = useUserStore();
  const userPrompt = gameStore.userInput;
  const targetPrompt =
    userStore.rounds[userStore.rounds.length - 1].preGeneratedImage.title;

  const jsonData = JSON.stringify({
    image1: origImageBase64,
    image2: generatedImage,
    target_prompt: targetPrompt,
    user_prompt: userPrompt
  });
  return await handleGameAPIResponse(
    axios.post(BASE_URL, jsonData, {
      timeout: process.env.VUE_APP_TIMEOUT_IMGCOMP
    }),

    JSON.parse
  );
}
