<template>
  <EndLayoutComponent v-if="rounds && rounds.length > 0">
    <EndHeader title="Game Analysis" class="header">
      <BadgeComponent variant="brand"
        >Level {{ currentRound.level }}
        <BadgeComponent>
          {{ rounds[activeRound].generatedImages?.length }}
          Attempt{{
            rounds[activeRound].generatedImages?.length > 1 ? 's' : ''
          }}</BadgeComponent
        >
      </BadgeComponent>
    </EndHeader>
    <img
      :src="rounds[activeRound].preGeneratedImage.src"
      class="analysis-image"
      alt="target-image"
    />
    <div class="generated-image-container">
      <div class="image-info">
        <p>Attempt {{ activeTry + 1 }}</p>
        <BadgeComponent
          :variant="currentRound?.survived ? 'success' : 'error'"
          :withBadge="false"
          >{{ currentRound?.score }} Points</BadgeComponent
        >
      </div>
      <img
        :src="getImageSource(currentRound?.src)"
        alt="generatedImage"
        class="analysis-image"
      />
    </div>
    <div class="side-bar">
      <img
        :key="ind"
        v-for="(genImage, ind) in rounds[activeRound]?.generatedImages"
        :src="getImageSource(genImage?.src)"
        :class="{ 'image-preview': true, '--won': genImage?.survived }"
        alt="preview of generatedImages"
        @click="changeTry(ind)"
      />
    </div>
    <p class="prompts">
      {{ rounds[activeRound]?.preGeneratedImage.title }}
    </p>
    <p class="prompts user-prompt">
      {{ currentRound?.title }}
    </p>
    <div class="footer">
      <ProgressComponent
        :key="index"
        v-show="rounds.length > 1"
        v-for="(round, index) in rounds"
        :filled="activeRound === index"
        @click="changeRound(index)"
      ></ProgressComponent>
    </div>
  </EndLayoutComponent>
  <EndLayoutComponent v-else>
    <EndHeader title="Game Analysis" class="header"> </EndHeader>
    <div>No round played yet</div>
  </EndLayoutComponent>
</template>

<script setup lang="ts">
import BadgeComponent from '@/components/BadgeComponent.vue';
import EndHeader from '@/features/EndHeader.vue';
import EndLayoutComponent from '@/components/EndLayoutComponent.vue';
import { useUserStore } from '@/stores/userStore';
import { computed, ref } from 'vue';
import ProgressComponent from '@/components/ProgressComponent.vue';

const userStore = useUserStore();
const activeRound = ref(0);
const activeTry = ref(0);
const rounds = userStore.rounds.filter(
  (singleRound) => singleRound.generatedImages.length > 0
);

const currentRound = computed(
  () => rounds[activeRound.value]?.generatedImages[activeTry.value]
);

const changeRound = (index: number) => {
  activeRound.value = index;
  activeTry.value = 0;
};

const getImageSource = (src: string) => {
  return src.length > 5 ? src : require('@/assets/base_images/placeholder.svg');
};

const changeTry = (index: number) => {
  activeTry.value = index;
};
</script>

<style lang="scss">
.header {
  grid-area: header;
}

.analysis-image {
  border-radius: 16px;

  @include image($imageheightEnd, $imagewidthEnd);
}

.footer {
  grid-area: footer;
  display: flex;
  justify-content: center;
  gap: 16px;
  max-width: 360px;
  justify-self: center;
  width: 100%;
  align-self: end;
}

.prompts {
  @include text-md_regular;
  width: calc(min(#{$imagewidthEnd}, #{$imageheightEnd}));
  justify-self: center;
  text-align: start;
  margin: auto;
}

.user-prompt {
  margin-left: 0;
}

.image-preview {
  width: 100%;
  height: 100px;
  border-radius: 16px;
  border: 1px solid $accent-red;
  box-shadow: 0px 0px 0px 4px rgba(240, 68, 56, 0.24);
  cursor: pointer;

  &.--won {
    border-color: $accent-green;
    box-shadow: 0px 0px 0px 4px rgba(56, 240, 99, 0.24);
  }
}

.side-bar {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: center;
  height: calc(min(#{$imagewidthEnd}, #{$imageheightEnd}));
}

.generated-image-container {
  border-radius: 16px;
  width: calc(min(#{$imagewidthEnd}, #{$imageheightEnd}));
  height: calc(min(#{$imagewidthEnd}, #{$imageheightEnd}));
  display: flex;
  align-self: center;
  position: relative;
}

.image-info {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(min(#{$imagewidthEnd}, #{$imageheightEnd}) - 32px);
  p {
    @include text-lg_semibold;
  }
}
</style>
