<template>
  <div class="score__overview">
    <ProgressComponent
      autoStart
      :value="score"
      :variant="won ? 'success' : 'error'"
      :duration="1500"
    />
    <Vue3autocounter
      ref="counter"
      class="score__counter"
      :startAmount="0"
      :endAmount="score"
      :duration="1.5"
      separator=""
      :autoinit="true"
      :suffix="getRandomEmojis(won)"
    />
  </div>
</template>

<script setup lang="ts">
import Vue3autocounter from 'vue3-autocounter';
import { getRandomEmojis } from '@/utils/dialogHelper';
import { useUserStore } from '@/stores/userStore';
import { computed } from 'vue';
import ProgressComponent from '@/components/ProgressComponent.vue';

const userStore = useUserStore();

const score = computed(() => userStore.currentRound?.score);
const won = computed(() => userStore.currentRound?.survived);
</script>

<style lang="scss">
.score__overview {
  display: flex;
  height: 180px;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  grid-area: footer;
  max-width: 1050px;
  width: 50%;
  justify-self: center;
}

.score__counter {
  color: $text-primary;
  @include text-2xl_semibold;

  @include phone {
    @include text-xl_semibold;
  }
}
</style>
