<template>
  <path
    id="Icon"
    d="M4.66602 10.0003H16.3327M16.3327 10.0003L10.4993 4.16699M16.3327 10.0003L10.4993 15.8337"
    stroke="white"
    stroke-width="1.66667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
