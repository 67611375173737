import { defineStore } from 'pinia';
import { useSessionStorage } from '@vueuse/core';
import { Round, User } from '@/models/User';
import { useGameStore } from './gameStore';

export const useUserStore = defineStore('user', {
  state: (): User => ({
    name: 'Invisible Fairy',
    level: 1,
    difficulty_level: 0,
    avatar_img: '',
    lives: 3,
    initialUserLives: 3,
    score: 0,
    rounds: useSessionStorage('rounds', [] as Round[]),
    globalNotification: '',
    id: -1,
    currentRound: undefined
  }),
  actions: {
    resetUserState() {
      this.level = 1;
      this.difficulty_level = 0;
      this.score = 0;
      this.lives = this.initialUserLives;
      this.rounds = [];
      this.clearRounds();
    },
    resetCurrentRound() {
      this.score -= this.currentRound?.score ?? 0;
      this.currentRound = undefined;
    },
    clearRounds() {
      sessionStorage.clear();
    },
    logNewRound(preGeneratedImagePath: string, preGeneratedImageTitle: string) {
      const round: Round = {
        preGeneratedImage: {
          src: preGeneratedImagePath,
          title: preGeneratedImageTitle
        },
        generatedImages: []
      };

      this.rounds.push(round);
    },
    updateWithGameResults(
      generatedImage: string,
      imageScore: number,
      survived: boolean
    ) {
      const gameStore = useGameStore();

      const score = Math.floor(imageScore * 100);

      const currentRound = this.rounds[this.rounds.length - 1];
      const generatedImageWithDetails = {
        src: generatedImage,
        title: gameStore.userInput,
        score,
        survived,
        level: this.level
      };
      this.currentRound = generatedImageWithDetails;
      this.score += score;
      this.lives = survived ? this.lives : this.lives - 1;
      currentRound.generatedImages.push(generatedImageWithDetails);

      this.rounds;
    },
    levelUp() {
      const gameStore = useGameStore();
      // Next Level
      this.level += 1;
      // First 2 levels simple, then 2 levels medium, last level complex (for now).
      if (this.level === 3 || this.level === gameStore.finalLevel) {
        this.difficulty_level += 1;
      }

      this.currentRound = undefined;
    }
  }
});
