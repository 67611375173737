<template>
  <GameHeader />

  <TargetImage
    :image="targetImage"
    v-if="!isPhone() || (!generatedImage && !gameStore.loadingImage)"
  ></TargetImage>
  <GeneratedImage
    v-if="!isPhone() || generatedImage || gameStore.loadingImage"
    :generatedImage="generatedImage"
  />

  <Transition name="fade" mode="out-in">
    <PromptInputForm
      ref="promptInputFormRef"
      @GameForm:promptSubmitted="handleGenerate"
      @formMounted="focusOnTextarea"
      v-if="showPromptInput"
      :key="restartKey"
    />
    <RoundResults v-else />
  </Transition>
  <AlertMessage
    :visible="snackbarVisible"
    :message="snackbarMessage"
    :type="snackbarType"
    @update:visible="snackbarVisible = $event"
  />
</template>

<script setup lang="ts">
import AlertMessage from '@/features/AlertMessage.vue';
import GameHeader from '@/features/GameHeader.vue';
import GeneratedImage from '@/features/GeneratedImage.vue';
import PromptInputForm from '@/features/PromptInputForm.vue';
import RoundResults from '@/features/RoundResults.vue';
import TargetImage from '@/features/TargetImage.vue';
import { postImageForScoring } from '@/services/scoreImageService';
import { postPromptText } from '@/services/text2ImageService';
import {
  MAX_CONCURRENT_REQUESTS,
  RoundStatus,
  currentRequests,
  remainingTime,
  useGameStore
} from '@/stores/gameStore';
import { useKeyboardStore } from '@/stores/keyboardStore';
import { useUserStore } from '@/stores/userStore';
import { selectAndLogNextImage } from '@/utils/gamePlayHelper';
import { isPhone } from '@/utils/responsiveHelper';
import {
  handleGameOver,
  isGameOver,
  replayRoundWithSameImage,
  useResetViewForNextTry
} from '@/utils/roundEndHelper';
import { updateCurrentRequests } from '@/utils/utilizatioHelper';
import {
  computed,
  nextTick,
  onBeforeMount,
  ref,
  watch,
  watchEffect
} from 'vue';

const gameStore = useGameStore();
const userStore = useUserStore();
const generatedImage = ref('');
const targetImage = ref('');
const keyboardStore = useKeyboardStore();
const promptInputFormRef = ref(null);
const restartKey = ref(0);

const snackbarVisible = ref(false);
const snackbarMessage = ref('');
const snackbarType = ref('info');

function showAlertMessage(message, type = 'info') {
  snackbarMessage.value = message;
  snackbarType.value = type;
  snackbarVisible.value = true;
  setTimeout(() => {
    snackbarVisible.value = false;
  }, 2000);
}

const showPromptInput = computed(() => {
  if (gameStore.scored) {
    return false;
  } else if (
    gameStore.userInput === '' &&
    gameStore.roundStatus === RoundStatus.EXPIRED
  ) {
    return false;
  }
  return true;
});

const focusOnTextarea = () => {
  setTimeout(() => {
    nextTick(() => {
      if (promptInputFormRef.value) {
        const textarea = promptInputFormRef.value.$el.querySelector('textarea');
        if (textarea) {
          textarea.focus();
        }
      }
    });
  }, 2000);
};

async function handleGenerate() {
  if (currentRequests.value >= MAX_CONCURRENT_REQUESTS) {
    gameStore.loadingImage = false;
    gameStore.triggerShake();
    showAlertMessage(
      'Too many active requests. Please try again later.',
      'error'
    );
  } else {
    gameStore.submitResponse();
    const imageGenerationAPIResponse = await postPromptText();

    if (
      imageGenerationAPIResponse.errorOccurred &&
      imageGenerationAPIResponse.errorResponseStatus
    ) {
      if (imageGenerationAPIResponse.errorResponseStatus === 400) {
        gameStore.loadingImage = false;
        gameStore.triggerShake();
        showAlertMessage(
          'Bad word detected. Please try again with another prompt.',
          'error'
        );
        setTimeout(() => {
          replayRoundWithSameImage();
          gameStore.roundStatus = RoundStatus.RUNNING;
        }, 2000);

        focusOnTextarea();
      } else if (imageGenerationAPIResponse.errorResponseStatus === 406) {
        gameStore.loadingImage = false;
        showAlertMessage(
          'NSFW content detected. Please try again with another prompt.',
          'error'
        );
        setTimeout(() => {
          replayRoundWithSameImage();
          restartKey.value++;
          focusOnTextarea();
          setTimeout(() => {
            remainingTime.value = 60;
          }, 3000);
        }, 2000);
      } else {
        gameStore.loadingImage = false;
        showAlertMessage(
          'An unexpected error occured. Please try again.',
          'error'
        );
        userStore.globalNotification = imageGenerationAPIResponse.errorMessage;
        setTimeout(() => {
          useResetViewForNextTry();
          focusOnTextarea();
        }, 2000);
      }
      return;
    }

    generatedImage.value = imageGenerationAPIResponse.data;

    const scoreAPIResponse = await postImageForScoring(
      targetImage.value,
      imageGenerationAPIResponse.data
    );

    if (scoreAPIResponse.errorOccurred) {
      userStore.globalNotification = scoreAPIResponse.errorMessage;
      useResetViewForNextTry();
      return;
    }

    userStore.updateWithGameResults(
      generatedImage.value,
      scoreAPIResponse.data.score,
      scoreAPIResponse.data.survived
    );
    gameStore.updateWithResults(scoreAPIResponse.data.survived);
  }
}

watchEffect(() => {
  if (!gameStore.scored) {
    generatedImage.value = '';
  }
});

watchEffect(() => {
  if (gameStore.roundStatus === RoundStatus.EXPIRED) {
    if (gameStore.userInput) {
      handleGenerate();
    } else {
      userStore.updateWithGameResults(generatedImage.value, 0, false);
      gameStore.updateWithResults(false);
      if (isGameOver()) {
        handleGameOver();
      }
    }
  }
});

watch(
  () => keyboardStore.isExpanded,
  (newValue, _) => {
    if (newValue) {
      setTimeout(() => {
        window.scrollBy({ top: -130, behavior: 'auto' });
      }, 100);
    }
  }
);
onBeforeMount(() => {
  updateCurrentRequests();
  gameStore.resetGameStateForNextRound();
  targetImage.value = selectAndLogNextImage();
});
</script>

<style lang="scss"></style>
