<template>
  <div class="game-start">
    <div class="game-start__content">
      <div class="game-start__inner-content">
        <img
          :src="require('@/assets/images/moaf-logo.svg')"
          :width="logoSize"
          :height="logoSize"
          alt="logo"
          class="game-start__logo"
        />

        <div>
          <h1 id="header">Welcome Art Student!</h1>

          <p>
            {{
              isPhone()
                ? `Perfect the art of forgery with AI descriptions, but beware – you have only three lives to avoid getting caught.`
                : `Do you want to become a master of art forgery to fool the art
            appreciation? Then you have to forge images using your description
            and the power of AI. But be careful not to get caught because you
            only have three lives.`
            }}
          </p>
        </div>

        <div class="game-start__form">
          <TextInput
            placeHolder="Invisible Fairy"
            :onEnter="startNewGame"
            labelName="Artist Name"
          />
          <button
            type="submit"
            class="primary-button"
            @click="startNewGame()"
            :disabled="userStore.name === ''"
          >
            Get Started
            <IconComponent>
              <LogIn />
            </IconComponent>
          </button>
        </div>
        <MobileFooterComponent v-if="isPhone()" :location="'start'" />
        <FooterComponent :location="'start'" v-else />
      </div>
    </div>
    <img
      src="@/assets/images/game-cover.png"
      alt="Parrot cover"
      class="game-start__cover"
    />
  </div>
</template>

<script setup lang="ts">
import { useGameStore, GameStatus } from '@/stores/gameStore';
import { useUserStore } from '@/stores/userStore';
import router from '@/router';
import { computed, onMounted, watch } from 'vue';
import TextInput from '@/components/TextInput.vue';
import LogIn from '@/assets/VueIcons/IconLogIn.vue';
import IconComponent from '@/components/IconComponent.vue';
import { ROUTES } from '@/router/routes';
import FooterComponent from '@/features/FooterComponent.vue';
import MobileFooterComponent from '@/features/MobileFooterComponent.vue';
import { isPhone } from '@/utils/responsiveHelper';
import { useKeyboardStore } from '@/stores/keyboardStore';

const gameStore = useGameStore();
const userStore = useUserStore();
const keyboardStore = useKeyboardStore();

const logoSize = computed(() => {
  if (!isPhone()) {
    return '245px';
  } else {
    return keyboardStore.isExpanded ? '100px' : '180px';
  }
});

function startNewGame() {
  gameStore.status = GameStatus.InProgress;
  router.push(ROUTES.DIALOG);
}

watch(
  () => keyboardStore.isExpanded,
  (newValue, _) => {
    if (newValue) {
      setTimeout(() => {
        window.scrollBy({ top: -150, behavior: 'auto' });
      }, 100);
    }
  }
);

onMounted(() => {
  userStore.resetUserState();
  gameStore.resetGameStateForNewGame();
  keyboardStore.setExpanded(false);
});
</script>

<style scoped lang="scss">
.game-start {
  display: flex;
  height: 100dvh;
  align-items: center;
  background: $bg-black-alt;
  justify-content: space-between;
  @include fadeIn;
}

.game-start__cover {
  flex: 1 0 0;
  align-self: stretch;
  max-width: 55%;
  flex: 1 0 0;
  object-fit: cover;
  border-radius: 80px 0px 0px 80px;
  @include fadeIn;

  @include laptop {
    max-width: 45%;
  }

  @include phone {
    display: none;
  }
}

.game-start__content {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: center;
  animation: contentIn 1s ease-in 0s;

  @include phone {
    height: 100dvh;
    justify-content: flex-start;
  }

  h1 {
    color: $text-primary;

    @include text-3xl_semibold;
    text-transform: unset;

    @include laptop {
      margin-top: $spacing-lg;
      margin-bottom: $spacing-xs;
    }

    @include phone {
      @include text-2xl_semibold;
      flex: none;
    }
  }

  p {
    color: $text-tertiary;
    @include text-md_regular;
  }
}

.game-start__inner-content {
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  align-items: center;
  gap: $spacing-4xl;
  align-self: center;
  max-width: 360px;

  @include laptop {
    gap: $spacing-lg;
  }

  @include phone {
    text-align: center;
    gap: $spacing-3xl;
    align-self: flex-start;
    padding-top: 40px;
  }
}

.primary-button {
  @include primaryButton;
}

.game-start__logo {
  @include laptop {
    height: 175px;
  }
}

.game-start__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;

  @include laptop {
    gap: $spacing-xl;
  }

  @include phone {
    text-align: left;
  }
}
</style>
