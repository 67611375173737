<template>
  <EndLayoutComponent>
    <div class="content">
      <div>
        <h1>Disclaimer</h1>
        <p>
          Master of Art Forgery is a game developed by
          <a href="https://www.inovex.de/en/data-protection/">inovex.de</a>.
          Please notice our
          <a href="https://www.inovex.de/en/data-protection/"
            >Data Protection</a
          >
          and
          <a href="https://www.inovex.de/en/legal-notice/">Legal Notice</a>. The
          following disclaimer adds additional information about “Master of Art
          Forgery”. Please carefully read and understand the following
          disclaimer before participating. Your involvement in the game implies
          your acceptance of these terms.
        </p>

        <section>
          <h3>1. Purpose and Scope</h3>
          <p>
            The Game employs generative AI to create images from text prompts,
            aiming to generate similar images for comparison. The game is
            designed for entertainment and creative exploration purposes.
          </p>
        </section>
        <section>
          <h3>2. Data Privacy</h3>
          <p>
            We do not collect any personal information from participants during
            their engagement in the Master of Art Forgery. No
            participant-generated content or information will be shared with
            third parties. Your creative contributions remain private and
            confidential. Participation does not require the provision of
            personal information, such as names, email addresses, or
            identifiers. Any non-personal information logged to the console is
            not saved anywhere and is solely for internal troubleshooting
            purposes.
          </p>
        </section>

        <section>
          <h3>3. Responsible Usage</h3>
          <p>
            Participants must follow responsible usage guidelines when utilizing
            the generative AI model. It is prohibited to purposefully generate
            or share images that contribute to hostile or alienating atmospheres
            for individuals. This entails avoiding the creation of images that
            may be unsettling, distressing, offensive, or perpetuate historical
            or present stereotypes.
          </p>
        </section>

        <section>
          <h3>4. Out-of-Scope Use</h3>
          <p>
            The generative AI model is not intended to produce factual or true
            representations of people or events. Therefore, using the model to
            generate content with the intention of presenting it as factual is
            considered out-of-scope for the abilities of this model.
          </p>
        </section>

        <section>
          <h3>5. Liability</h3>
          <p>
            Participants in the Master of Art Forgery understand that their
            participation is voluntary and carries inherent risks. inovex GmbH
            is The organizers and facilitators of the game are not responsible
            for any damages, losses, or outcomes resulting from participants'
            actions, usage of the generative AI model, or involvement in the
            game. Participants are solely accountable for their interactions
            with the model and for adhering to the provided guidelines. The
            organizers assume no liability for the content generated by
            participants and shall not be held responsible for any misuse,
            violations, or disputes that may arise in connection with the game.
            Participants agree to this liability disclaimer by participating.
          </p>
        </section>

        <section>
          <h3>6. Compliance and Accountability</h3>
          <p>
            Participants are solely responsible for their actions while using
            the generative AI model for the game. By participating in the Master
            of Art Forgery, you acknowledge and agree to abide by the terms
            outlined in this disclaimer. The game team holds the right to take
            appropriate action against any violations or misuse. Remember, the
            primary goal of the game is to promote creativity and fun within a
            responsible and respectful environment. Enjoy the experience and let
            your creativity shine!
          </p>

          <p><br />Thank you for your cooperation and understanding.</p>
        </section>
      </div>
    </div>
  </EndLayoutComponent>
</template>

<script>
export default {
  name: 'GameDisclaimer'
};
</script>

<style lang="scss">
.content {
  flex: 0 0 80%;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
</style>
