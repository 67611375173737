<template>
  <g id="trophy-01" clip-path="url(#clip0_21_35750)">
    <path
      id="Icon"
      d="M10.0013 12.5003C7.23988 12.5003 5.0013 10.2617 5.0013 7.50033V2.8707C5.0013 2.52582 5.0013 2.35339 5.05156 2.21531C5.13581 1.98384 5.31815 1.8015 5.54962 1.71725C5.6877 1.66699 5.86013 1.66699 6.20501 1.66699H13.7976C14.1425 1.66699 14.3149 1.66699 14.453 1.71725C14.6845 1.8015 14.8668 1.98384 14.951 2.21531C15.0013 2.35339 15.0013 2.52582 15.0013 2.8707V7.50033C15.0013 10.2617 12.7627 12.5003 10.0013 12.5003ZM10.0013 12.5003V15.0003M15.0013 3.33366H17.0846C17.4729 3.33366 17.6671 3.33366 17.8202 3.39709C18.0244 3.48167 18.1866 3.6439 18.2712 3.84809C18.3346 4.00123 18.3346 4.19537 18.3346 4.58366V5.00033C18.3346 5.7753 18.3346 6.16279 18.2495 6.48071C18.0183 7.34344 17.3444 8.01731 16.4817 8.24847C16.1638 8.33366 15.7763 8.33366 15.0013 8.33366M5.0013 3.33366H2.91797C2.52968 3.33366 2.33554 3.33366 2.1824 3.39709C1.97821 3.48167 1.81598 3.6439 1.7314 3.84809C1.66797 4.00123 1.66797 4.19537 1.66797 4.58366V5.00033C1.66797 5.7753 1.66797 6.16279 1.75315 6.48071C1.98432 7.34344 2.65819 8.01731 3.52092 8.24847C3.83884 8.33366 4.22633 8.33366 5.0013 8.33366M6.20501 18.3337H13.7976C14.0021 18.3337 14.168 18.1678 14.168 17.9633C14.168 16.3269 12.8414 15.0003 11.205 15.0003H8.7976C7.1612 15.0003 5.83464 16.3269 5.83464 17.9633C5.83464 18.1678 6.00046 18.3337 6.20501 18.3337Z"
      stroke="#CECFD2"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_21_35750">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
