<template>
  <form class="game-form__container" @submit.prevent="handlePrompt">
    <PromptTextArea
      class="game-form__col"
      :class="{ 'shake-animation': gameStore.shakeTrigger }"
      @TextArea:triggerSubmit="handlePrompt"
    />
    <div class="game-form__col game-form__right-row">
      <Vue3autocounter
        v-if="gameStore.status === GameStatus.InProgress"
        :class="{
          'game-form__counter': true,
          running: gameStore.roundStatus === RoundStatus.RUNNING
        }"
        ref="currentRoundCountdown"
        :startAmount="60"
        :endAmount="0.0"
        :duration="60"
        :decimals="2"
        decimalSeparator=":"
        :autoinit="false"
        @finished="roundCountdownExpired()"
      />
      <div ref="convertedCountdown" class="styled-text"></div>
      <button
        type="submit"
        class="primary-button"
        :disabled="
          gameStore.roundStatus === RoundStatus.SUBMITTED ||
          gameStore.userInput === ''
        "
      >
        {{ !isPhone() ? 'Generate' : '' }}

        <IconComponent>
          <IconMagicWand />
        </IconComponent>
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import PromptTextArea from '@/features/PromptTextArea.vue';
import {
  GameStatus,
  useGameStore,
  RoundStatus,
  currentRequests,
  MAX_CONCURRENT_REQUESTS,
  remainingTime
} from '@/stores/gameStore';
import Vue3autocounter from 'vue3-autocounter';
import IconComponent from '@/components/IconComponent.vue';
import IconMagicWand from '@/assets/VueIcons/IconMagicWand.vue';
import { isPhone } from '@/utils/responsiveHelper';
import { updateCurrentRequests } from '@/utils/utilizatioHelper';

const currentRoundCountdown = ref(null);

const gameStore = useGameStore();
const emit = defineEmits(['GameForm:promptSubmitted', 'formMounted']);

function roundCountdownExpired() {
  remainingTime.value = 0;
  gameStore.roundStatus = RoundStatus.EXPIRED;
  currentRoundCountdown.value?.reset();
}

function handlePrompt() {
  updateCurrentRequests();
  if (currentRequests.value >= MAX_CONCURRENT_REQUESTS) {
    currentRoundCountdown.value.pause();
    remainingTime.value = currentRoundCountdown.value.$data.currentAmount;
    gameStore.roundStatus = RoundStatus.INITIAL;
  }
  if (gameStore.userInput != '') {
    remainingTime.value = currentRoundCountdown.value.$data.currentAmount;
    emit('GameForm:promptSubmitted');
  }
}

watchEffect(() => {
  if (!currentRoundCountdown.value) {
    return;
  }
  if (gameStore.roundStatus === RoundStatus.SUBMITTED) {
    currentRoundCountdown.value.pause();
  } else if (gameStore.roundStatus === RoundStatus.RUNNING) {
    currentRoundCountdown.value.resume();
  }
});

onMounted(() => {
  if (gameStore.roundStatus === RoundStatus.INITIAL) {
    window.setTimeout(() => {
      gameStore.roundStatus = RoundStatus.RUNNING;

      if (currentRoundCountdown.value) {
        currentRoundCountdown.value?.reset();
        currentRoundCountdown.value?.start();
      }
    }, 2000);
  }
  emit('formMounted');
});
</script>

<style scoped lang="scss">
.game-form__container {
  display: contents;
  gap: 32px;
  @include phone {
    display: flex;
    flex-direction: row;
    grid-area: footer;
    gap: 8px;
    margin: 48px 12px;
    align-items: flex-end;
  }
}

.game-form__counter {
  color: $text-primary;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  letter-spacing: 8px;

  @include phone {
    display: none;
  }

  &.running {
    animation: currentRoundCountdown 65s;
  }
}

.game-form__col {
  width: calc(min(#{$imageheight}, #{$imagewidth}));
  justify-self: center;

  @include phone {
    width: 100%;
  }
}

.game-form__right-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px 0;
  justify-self: center;

  @include phone {
    padding: 0;
    justify-content: flex-end;
    width: 48px;
  }
}

@keyframes currentRoundCountdown {
  0% {
    color: $text-primary;
    opacity: 1;
  }
  50% {
    color: $text-primary;
    transform: none;
  }
  65% {
    color: $accent-red;
    transform: scale(1.05) translateX(0px) translateY(0px);
  }
  90% {
    transform: scale(1.2) translateX(0px) translateY(0px);
    color: $accent-red;
    opacity: 1;
  }
}

.primary-button {
  @include phone {
    width: 48px;
    padding: 10px;
    background-color: $primary-violett;
  }
  @include primaryButton;
  width: calc(min(#{$imageheight}, #{$imagewidth}));
  align-self: center;
  max-height: 48px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.shake-animation {
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
