<template>
  <!--
Vue component that displays a badge with different styles based on the provided props.
The badge can have different variants and can optionally include additional styling.
-->
  <div :class="`badge --${props.variant}  ${props.withBadge && '--withBadge'}`">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String,
    default: 'plain',
    validator: (value: string) =>
      ['brand', 'success', 'warning', 'error', 'plain'].includes(value)
  },
  withBadge: {
    type: Boolean,
    default: true
  }
});
</script>

<style scoped lang="scss">
.badge {
  justify-content: flex-end;
  padding: 4px 12px;
  border-radius: $radius-full;
  border: 1px solid;
  align-items: center;
  gap: $spacing-lg;
  display: inline-flex;
  transition: width 0.5s ease-in;

  @include text-sm_medium;
}

.--withBadge {
  padding: $spacing-xs;
  padding-left: 14px;
}

.--error {
  color: $error-700;
  background-color: $error-100;
  border-color: $error-200;
}

.--warning {
  color: $warning-700;
  background-color: $warning-100;
  border-color: $warning-200;
}

.--success {
  color: $success-700;
  background-color: $success-100;
  border-color: $success-200;
}

.--plain {
  background-color: $bg-black-primary;
  color: inherit;
  padding: 2px 8px;
}

.--brand {
  color: $brand-700;
  background-color: $brand-100;
  border-color: $brand-200;

  &:hover {
    background-color: $brand-150;
  }
}
</style>
