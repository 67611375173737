<template>
  <img
    :src="require('@/assets/images/moaf-logo.svg')"
    :width="isPhone() ? '100' : '245'"
    :height="isPhone() ? '100' : '245'"
    alt="logo"
    class="icon-wrapper"
  />
  <EndLayoutComponent class="layout">
    <div class="game-over-btn-container">
      <ProgressCircle :score="userStore.score" />

      <p>
        {{ userStore.name ?? 'Invisible Fairy' }}, <br />
        you rank {{ userRank }} out of {{ leaderboardLength }}. 🏆
      </p>
    </div>
    <div class="end__progress-bar">
      <ProgressComponent autoStart @click="$router.push(ROUTES.GAME_OVER)" />
      <ProgressComponent @click="$router.push(ROUTES.END_MENU)" />
    </div>
  </EndLayoutComponent>
</template>
<script setup lang="ts">
import { useUserStore } from '@/stores/userStore';
import ProgressCircle from '@/components/ProgressCircle.vue';
import ProgressComponent from '@/components/ProgressComponent.vue';
import { getLeaderboard } from '@/services/leaderboardService';
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { ROUTES } from '@/router/routes';
import EndLayoutComponent from '@/components/EndLayoutComponent.vue';
import { isPhone } from '@/utils/responsiveHelper';
import router from '@/router';

const userStore = useUserStore();
const leaderboardLength = ref(0);
const userRank = ref(0);

async function calcUserRank() {
  const leaderboard = await getLeaderboard();

  if (leaderboard.data) {
    leaderboardLength.value = leaderboard.data?.length || 0;
    userRank.value =
      leaderboard.data.findIndex((element) => element[0] === userStore.id) + 1;
  }
}

onBeforeMount(async () => await calcUserRank());

let timer: number;

function startTimer() {
  timer = setInterval(() => {
    router.push(ROUTES.END_MENU);
  }, 5000);
}
function stopTimer() {
  clearInterval(timer);
}

onMounted(startTimer);
onBeforeUnmount(stopTimer);
</script>

<style scoped lang="scss">
.game-over-btn-container {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 5;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include laptop {
    margin-top: 140px;
  }
  p {
    color: $text-primary;
    text-align: center;

    /* Display xs/Semibold */
    @include text-xs_semibold /* 133.333% */;
  }
}

.icon-wrapper {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.end__progress-bar {
  color: white;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 5;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  gap: 16px;
}

.layout {
  background-image: url('@/assets/images/moaf-image-result.png');
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-top: 132px;
  @include fadeIn;
}
</style>
