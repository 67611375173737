import { fetchUtilization } from '@/services/utilizationService';
import { currentRequests } from '@/stores/gameStore';

export function extractRequestCount(data) {
  let requestCount = data.bentoml_api_server_request_in_progress;

  if (isNaN(requestCount)) {
    requestCount = 0;
  }

  return Math.round(requestCount);
}

export function getRandomElement(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export function updateCurrentRequests() {
  fetchUtilization()
    .then((response) => {
      currentRequests.value = response.data;
    })
    .catch((error) => {
      console.error('Error fetching active requests:', error);
    });
}
