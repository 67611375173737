<template>
  <div class="retry-form">
    <p class="form__header">{{ headerText }}</p>
    <div class="buttons">
      <button @click="handleNextRoundOrReplay" class="primary-button">
        <IconComponent v-show="!survived">
          <IconArrowLeft />
        </IconComponent>
        {{ buttonText }}
        <IconComponent v-show="survived">
          <IconArrowRight />
        </IconComponent>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconArrowLeft from '@/assets/VueIcons/IconArrowLeft.vue';
import IconArrowRight from '@/assets/VueIcons/IconArrowRight.vue';
import IconComponent from '@/components/IconComponent.vue';
import { inRetryForm, remainingTime } from '@/stores/gameStore';
import { useUserStore } from '@/stores/userStore';

import {
  playNextLevel,
  replayRoundWithSameImage
} from '@/utils/roundEndHelper';
import { computed } from 'vue';

const userStore = useUserStore();

const survived = userStore.currentRound.survived;

const buttonText = computed(() => {
  return survived ? "Awesome, let's move on" : "Yes, let's try again";
});

const headerText = computed(() => {
  return survived
    ? "You're ready for the next round!"
    : `Ohhhh noo...
    I know you've got what it takes. Now's your chance to shine!`;
});

function handleNextRoundOrReplay() {
  inRetryForm.value = true;
  if (survived) {
    playNextLevel();
    remainingTime.value = 60;
  } else {
    replayRoundWithSameImage();
    setTimeout(() => {
      remainingTime.value = 60;
    }, 3000);
  }
}
inRetryForm.value = false;
</script>

<style lang="scss">
.retry-form {
  width: 100%;
  display: flex;
  height: 180px;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  grid-area: footer;
}

.form__header {
  @include text-2xl_semibold;
  color: $text-primary;
  text-align: center;
  white-space: pre-line;

  @include phone {
    @include text-md_regular;
  }
}

.buttons {
  display: flex;
  gap: 32px;
  max-width: 360px;
  justify-self: center;
  width: 100%;

  @include phone {
    flex-direction: column-reverse;
    padding-bottom: 2px;
    gap: 24px;
  }
}

.primary-button {
  @include primaryButton;
  flex: 1;
}

.secondary-button {
  @include secondaryButton;
  flex: 1;
}
</style>
