import { APIResponse } from '@/models/APIResponse';

function parseErrorMessage(error: any) {
  if (error.code == 'ECONNABORTED') {
    console.error('Error: Request timed out');
    return `${error}`;
  }
  if (error.response) {
    // The request was made and the server responded with an error status code
    console.error('Response Error:', error.message);
    switch (error.response.status) {
      case 400: // bad request
        console.error('Error: Harmful word detected');
        return 'Oops! It seems like the request contains a harmful word, which we cannot process. Please review your input and try again without including any inappropriate language.';
      case 408:
        console.error('Error: Request took too long to process.');
        return 'Looks like the request took too long to process. Please check your internet connection and try generating the image again. ';
      case 429:
        console.error('Error: User made too many requests.');
        return 'Hold on! You have made too many requests too quickly. Please wait a moment before trying again.';
      case 500:
        console.error(
          'Error: Problem while processing image generation request.'
        );
        return 'Oops! Something unexpected happened on our end while processing your image generation request. Please try generating the image again in a moment.';
      case 502:
        console.error(
          'Error: Problem while connecting to image generation servers.'
        );
        return 'Uh-oh! Something went wrong while connecting to our image generation servers. Please try again later.';
      case 503: // bentoml service unavailable
        console.error('Error: Image Generation Service is down ');
        return 'Sorry, our image generation service is temporarily unavailable. Please check back in a little while.';
    }
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Request Error:', error.message);
    console.error('Request Error: Unable to Connect to Server');
    return 'Oops! We are having trouble connecting to the server right now. Please check your internet connection and try again.';
  } else {
    // Unexpected error during request setup
    console.error('Unexpected Error:', error.message);
    return 'Oops, service is currently unavailable. Please try again later.';
  }
}
/**
 * Handle the response from a game API call.
 * @template T The type of the data expected in the API response.
 * @param {Promise<any>} apiCall - The API call to handle.
 * @param {(data: T) => T} [successCallback] - An optional callback to run on the data if the API call is successful.
 * @returns {Promise<APIResponse<T>>} The API response, with the data processed by the success callback if provided.
 */
async function handleGameAPIResponse<T>(
  apiCall: Promise<any>,
  successCallback?: (data: T) => T
): Promise<APIResponse<T>> {
  const returnValue: APIResponse<T> = {
    data: undefined,
    errorOccurred: false,
    errorMessage: null,
    errorResponseStatus: 200
  };

  try {
    const response = await apiCall;
    returnValue.data = successCallback
      ? successCallback(response.data)
      : response.data;
  } catch (error) {
    returnValue.errorOccurred = true;
    returnValue.errorMessage = parseErrorMessage(error);
    if (error.response) {
      returnValue.errorResponseStatus = error.response.status;
    }
  }

  return returnValue;
}

export { handleGameAPIResponse };
