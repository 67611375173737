<template>
  <div
    class="circle per-25"
    :class="{ paused: gameStore.roundStatus !== RoundStatus.RUNNING }"
  >
    <img :src="image" alt="Target image" class="target-image inner" />
  </div>
</template>

<script setup lang="ts">
import {
  MAX_CONCURRENT_REQUESTS,
  RoundStatus,
  currentRequests,
  inRetryForm,
  useGameStore
} from '@/stores/gameStore';
import { isPhone } from '@/utils/responsiveHelper';
import { onMounted, watch } from 'vue';
import { remainingTime } from '@/stores/gameStore';

defineProps(['image']);

const gameStore = useGameStore();

const generateKeyframes = () => {
  let keyframes = '';

  const keyFrameElement = document.getElementById('progress-keyframes');

  if (keyFrameElement) {
    document.head.removeChild(keyFrameElement);
  }
  const startFraction = 1 - remainingTime.value / 60;

  for (let i = 0; i <= 1000 * (1 - startFraction); i++) {
    const adjustedFraction = startFraction + i / 1000;

    keyframes += `${i / 10}% {
              background-image: conic-gradient(#333741 ${
                adjustedFraction * 100
              }%, #b692f6 0);
            }\n`;
  }

  // Create a style element and set the keyframes
  const style = document.createElement('style');
  style.id = 'progress-keyframes';
  style.innerHTML = `
          @keyframes progress {
            ${keyframes}
          }
          `;

  document.head.appendChild(style);
};

watch(remainingTime, () => {
  if (isPhone()) {
    if (
      currentRequests.value >= MAX_CONCURRENT_REQUESTS &&
      !inRetryForm.value
    ) {
      return;
    } else {
      const circle = handleTimerFinished();
      circle.classList.add('paused');
    }
  }
});

onMounted(() => {
  if (isPhone()) {
    generateKeyframes();
  }
});

function handleTimerFinished() {
  const circle = document.querySelector('.circle') as HTMLElement;
  circle.classList.remove('paused');
  circle.style.animation = 'none';
  circle.offsetHeight; // Trigger reflow to restart the animation
  // Re-generate the keyframes
  generateKeyframes();
  circle.style.animation = 'progress 60s linear';
  return circle;
}

window.addEventListener('timerFinished', handleTimerFinished);
</script>

<style scoped lang="scss">
$imageWidthPhone: calc(min($imageheight, $imagewidth));
.target-image {
  @include image($imageheight, $imagewidth);
  grid-area: image1;
  @include phone {
  }
}

.circle {
  position: relative;
  justify-self: center;
  display: flex;
  justify-content: center;
  @include phone {
    width: calc(min(#{$imageheight}, calc(#{$imagewidth} * 2)) + 40px);
    height: calc(min(#{$imageheight}, calc(#{$imagewidth} * 2)) + 40px);
    margin: 12px;
    border-radius: 32px;
    background: #b692f6;
    overflow: hidden;
  }
}

.circle .inner {
  @include phone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #161b26;
    border-radius: 26px;
    padding: 12px;
  }
}
.circle {
  @include phone {
    animation: progress 60s linear;
    animation-delay: 0s;

    &.paused {
      animation-play-state: paused;
    }
  }
}
</style>
