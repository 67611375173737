<template>
  <img
    :src="require('@/assets/images/moaf-logo.svg')"
    :width="isPhone() ? '100' : '245'"
    :height="isPhone() ? '100' : '245'"
    alt="logo"
    class="icon-wrapper"
  />

  <EndLayoutComponent class="layout">
    <div class="game-over-btn-container">
      <button
        @click="$router.push(ROUTES.LEADERBOARD)"
        class="secondary-button button"
      >
        Hall Of Fame <IconComponent><IconTable /></IconComponent>
      </button>
      <button
        class="secondary-button button"
        @click="
          isPhone()
            ? $router.push(ROUTES.ANALYSIS_MOBILE)
            : $router.push(ROUTES.ANALYSIS)
        "
      >
        Game Analysis <IconComponent><IconLineChart /></IconComponent>
      </button>
      <DividerComponent> OR </DividerComponent>
      <button class="primary-button button" @click="handleNewGame()">
        Play Again <IconComponent><IconPalette /></IconComponent>
      </button>
    </div>
    <div class="end__progress-bar">
      <ProgressComponent filled @click="$router.push(ROUTES.GAME_OVER)" />
      <ProgressComponent filled @click="$router.push(ROUTES.END_MENU)" />
    </div>
  </EndLayoutComponent>
</template>
<script setup lang="ts">
import { useUserStore } from '@/stores/userStore';

import ProgressComponent from '@/components/ProgressComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import IconLogo from '@/assets/VueIcons/IconLogo.vue';
import IconTable from '@/assets/VueIcons/IconTable.vue';
import IconLineChart from '@/assets/VueIcons/IconLineChart.vue';
import IconPalette from '@/assets/VueIcons/IconPalette.vue';
import router from '@/router';
import { ROUTES } from '@/router/routes';
import { useGameStore } from '@/stores/gameStore';
import EndLayoutComponent from '@/components/EndLayoutComponent.vue';
import { isPhone } from '@/utils/responsiveHelper';

const userStore = useUserStore();
const gameStore = useGameStore();

const handleNewGame = () => {
  userStore.resetUserState();
  gameStore.resetGameStateForNewGame();

  router.push(ROUTES.START);
};
</script>

<style scoped lang="scss">
.game-over-btn-container {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 4;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  gap: 12px;

  @include laptop {
    margin-top: 140px;
  }
  p {
    color: $text-primary;
    text-align: center;

    /* Display xs/Semibold */
    @include text-xs_semibold /* 133.333% */;
  }

  @include phone {
    justify-content: flex-end;
    height: 50dvh;
  }
}

.button {
  width: 100%;
  max-height: 44px;
}

.secondary-button {
  @include secondaryButton;
  width: 100%;
}

.primary-button {
  @include primaryButton;
  width: 100%;
}

.icon-wrapper {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.end__progress-bar {
  color: white;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 5;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 150px;
  gap: 16px;

  @include tablet {
    justify-content: flex-end;
  }
}

.layout {
  margin-top: 132px;
  @include fadeIn;
}
</style>
