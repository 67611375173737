<template>
  <!--
Progress Bar component. This component is used to display a horizontal progress bar.
-->
  <div class="progress-bar">
    <div
      :class="`progress --${props.variant}`"
      :style="{ width: progress + '%' }"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

const progress = ref(0);
const props = defineProps({
  autoStart: Boolean,
  filled: Boolean,
  value: {
    type: Number,
    default: 100
  },
  variant: {
    type: String,
    default: 'plain',
    validator: (value: string) => ['success', 'error', 'plain'].includes(value)
  },
  duration: {
    type: Number,
    default: 5000
  }
});

watch(
  () => props.filled,
  (filledNew) => {
    progress.value = filledNew ? props.value : 0;
  }
);

onMounted(() => {
  if (props.autoStart) {
    fillProgressBarOverTime(); // Füllt die Fortschrittsleiste über 5 Sekunden
  }
  if (props.filled) {
    progress.value = props.value;
  }
});

/**
 * Function to fill the progress bar over time.
 * It calculates the elapsed time since the start time and updates the progress value accordingly.
 * If the elapsed time is less than the duration, it requests the next animation frame and calls itself again.
 * If the elapsed time is equal to or greater than the duration, it sets the progress value to the maximum value.
 */
const fillProgressBarOverTime = () => {
  const startTime = performance.now();
  const updateProgress = () => {
    const elapsedTime = performance.now() - startTime;
    if (elapsedTime < props.duration) {
      progress.value = (elapsedTime / props.duration) * props.value;
      requestAnimationFrame(updateProgress);
    } else {
      progress.value = props.value;
    }
  };
  requestAnimationFrame(updateProgress);
};
</script>

<style lang="scss">
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: $secondary-border;
  border-radius: $radius-full;
  overflow: hidden;
  height: 6px;
  position: relative;
  cursor: pointer;
}

.progress {
  position: absolute;
  height: 6px;
  top: 0;
  left: 0;
  background-color: $text-secondary;
  transition: width 0.1s ease; /* optional transition for smooth progress fill */

  &.--success {
    background-color: $accent-green;
  }

  &.--error {
    background-color: $accent-red;
  }
}
</style>
